
/* editor css start */
.editor-sec .editor-container .colmn-2 {
    flex: 0 0 100%;
    max-width: 75%;
}

.colmn-preview-wrapper {
    /* height: 100vh; */
    height: calc(100vh - 110px);
}

.editor-sec .editor-container .colmn-1 {
    flex: 0 0 100%;
    max-width: 25%;
}


.editor-sec {
    background: #000000;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding-bottom: 110px;
    min-height: 500px;
}

.save-render-sec {
    background-color: #0c0c0c9e;
    padding: 10px;
    max-width: max-content;
    margin-left: auto;
}

.save-render-sec button {
    font-size: 14px;
}

/* .render-projectbtn {
    margin-right: 85px;
} */
.editor-part {
    overflow-y: auto;
    overflow-x: hidden;
    background: #191919;
    /* padding: 10px 0px; */
    height: calc(100vh - 115px);
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.editor-part::-webkit-scrollbar {
    width: 5px;
    height: 10em;
}

.editor-part::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px grey;
    border-radius: 10px;
}

.editor-part::-webkit-scrollbar-thumb {
    background: #00F5A0 !important;
    border-radius: 10px;
    height: 10px;
}

.save-render-sec .add-campaign input {
    border-radius: 30px;
    border: none;
    background: #161616;
    width: 290px;
    height: 42px;
    font-size: 14px;
}

.save-render-sec ::placeholder{
    color:#A5A5A5;
}

.save-render-sec .add-campaign .form-control{
    color:#fff;
}

.save-render-sec .save-projectbtn button{
    /* background: linear-gradient( to right , #00FFBA , #3B91CB); */
    background: #ffffff;
    background: -moz-linear-gradient(left,  #ffffff 0%, #ffffff 50%, #00ffba 50%, #3b91cb 100%);
    background: -webkit-linear-gradient(left,  #ffffff 0%,#ffffff 50%,#00ffba 50%,#3b91cb 100%);
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 50%,#00ffba 50%,#3b91cb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#3b91cb',GradientType=1 );
    background-size: 202%;
    background-position: 99% 50%;
    transition: .3s ease-in-out;
    padding: 7px 15px;
    border-radius: 5px;
    border:none;
    color:#fff;
}

.save-render-sec .render-projectbtn button{
    /* background: linear-gradient(to right , #A72CE3 , #CB3B71); */
    background: #ffffff;
    background: -moz-linear-gradient(left,  #ffffff 0%, #ffffff 50%, #a72ce3 50%, #cb3b71 100%);
    background: -webkit-linear-gradient(left,  #ffffff 0%,#ffffff 50%,#a72ce3 50%,#cb3b71 100%);
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 50%,#a72ce3 50%,#cb3b71 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#cb3b71',GradientType=1 );
    background-size: 202%;
    background-position: 99% 50%;
    transition: .3s ease-in-out;
    padding: 7px 25px;
    border-radius: 5px;
    border:none;
    color:#fff;
}

.editor-tab-menu .nav-item .nav-link h6 {
    color: #fff;
    margin-top: 5px;
    font-size: 12px;
    font-family: var(--poppins);
    margin-bottom: 0px;
}

.editor-tab-menu .nav-item {
    padding: 0px 10px;
}

.editor-tab-menu .nav-item .nav-link.active {
    background: #414141;
    border-radius: 10px;
    padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link.active h6{
    color:#00FFB1;
}

.editor-tab-menu .nav-item .nav-link.active svg path {
    fill: #00FFB1;
}

.editor-tab-menu .nav-item .nav-link{
    padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link .img-wrapper {
    width: 24px;
    height: 28px;
}

.editor-tab-menu{
    padding: 10px;
}

.editor-preview{
    padding: 3vh 2vw;
    padding-bottom: 200px;
    background: #333333;
    position: relative;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.editor-preview::-webkit-scrollbar {
    width: 5px;
}
.editor-preview::-webkit-scrollbar-thumb {
    background: #00f5a0;
    border-radius: 10px;
}
.editor-preview::-webkit-scrollbar-track {
    background: #0000;
}

.aspect-ratio-wrapper {
    position: absolute;
    /* background: #fff; */
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* border-radius: 10px; */
    margin: auto;
    /* top: 0; */
    bottom:0;
    left: 0;
    right: 0;
    /* box-shadow: 0px 0px 15px #00000087; */
}

.story-part-sec .story-para p{
    font-size: 12px;
    font-family: var(--poppins);
    color:#fff;
}

.story-part-sec .story-para {
    background-color: #242424;
    padding: 20px;
    border-radius: 30px;
    border: 1px solid #707070;
    cursor: pointer;
}

.editor-preview-box {
    width: 100%;
    height: auto;
    /* overflow: hidden; */
    margin: auto;
}

.ep-as-r-9-16 .editor-preview-box {
    transform: scale(1.5);
    transform-origin: 50% 105%;
}

.aspect-ratio-wrapper img,
.preview-def-wh,
.aspect-ratio-wrapper video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.para-active{
    background-color: #2DA882 !important;
}

.para-unactive {
    background: none;
}

.slide-box ul li {
    flex: 0 0 auto;
    /* flex-basis: auto; */
    width: auto;
    height: 70px;
    margin: 0px 10px;
    overflow: hidden;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    border: 2px solid #707070;
    transition: .3s ease-in-out;
    position: relative;
}

.slide-box ul li .box-ar-wrapper img,
.slide-box ul li .box-ar-wrapper video {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
}

.slide-box ul li .box-ar-wrapper {
    
    background-color: #101010;
    border-radius: 10px;
    overflow: hidden;
}

.slide-box ul li:hover{
    border-color: #00F5A0;
    transition: .3s ease-in-out;
}


.slide-box {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
    display: flex;
    align-items: center;
}

.slide-box::-webkit-scrollbar-thumb {
    background: #00f5a0;
    border-radius: 10px;
}

.slide-box::-webkit-scrollbar-track {
    background: #0000;
}

.slide-box::-webkit-scrollbar {
    height: 4px;
}

.slide-sec ul {
    /* width: 1350px; */
    width: max-content;
    /* margin: 0 auto!important;
    justify-content: center; */
}

.slide-sec{
    background-color: #000000;
    padding: 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
}

.slide-box .plus-icon i{
    color:#565656;
}

/* Control Box Start */

.control-time {
    width: 100%;
    background: transparent;
    border: none;
    color:#fff !important;
    /* font-size: 0.7vw; */
    font-size: 10px;
    /* margin-right: 10px; */
}

.control-time:focus-visible{
    outline: none !important;
}

.control-time::placeholder{
    color:#fff;
}

.ct-end {
    text-align: right;
}

.controls-box {
    background: #000;
    padding: 2vh 0.5vw;
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 3px 5px -1px #000;
    /* height: 70px; */
    display: flex;
    align-items: center;
    justify-content: center;
    top: -4px;
}

.controls-box .cb-col-3 .start-end-time {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
}

.controls-box .cb-col-3 input {
    text-align: right;
}

.play-push-left-right ul img {
    /* max-width: 1.1vw; */
    max-width: 10px;
}

.control-time:focus{
    box-shadow: none !important;
}

/* Control Box End */

.save-projectbtn button:hover{
    color:#2fcac9;
}

.save-projectbtn button:focus{
    background-color: #fff !important;
    background-image: unset !important;
    color:#2fcac9 !important;
}
.add-campaign input:focus{
    box-shadow: none;
}

.render-projectbtn button:hover{
    color:#2fcac9;
}

.render-projectbtn button:focus{
    background-color: #fff !important;
    background-image: unset !important;
    color:#2fcac9 !important;
}

/* .style-upload-part {
   background: #303030;
   padding: 10px;
   border-radius: 10px;
} */

.style-upload-box {
    background-color: #171717;
    height: 77px;
    border: 1px dashed #707070;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style-upload-box .custom-file-input{
    position: absolute;
    top: 0;
    opacity: 0;
    background: red;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.style-upload-box h6{
    font-size: 12px;
    margin-bottom: 0px;
    color:#8F8F8F;
}

.style-upload-box .custom-file{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.position-text-box {
    /* background: #303030; */
    padding: 0;
    border-radius: 10px;
    height: 100%;
}
.position-text-box .text-position-dropdown label {
    font-size: 14px;
    margin-left: 4px;
}

.style-part-sec {
    padding: 24px 15px 0;
}

.style-box-wrapper .colmn {
    background: #303030;
    height: 165px!important;
    padding: 15px;
    border-radius: 10px;
}

.style-box-wrapper .colmn.col-md-8 {
    max-width: 63%;
}

.style-upload-part .style-remove-logo .form-check-input {
    position: unset;
}

.style-upload-part .style-remove-logo .form-switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.style-remove-logo .remove-logo-switch-btn {
    width: 100%;
}

.position-text-box .text-position-dropdown .box:hover{
    background-color: #00BF85;
    transition: all .2s;
    border:2px solid #00BF85;
}
.position-text-box .text-position-dropdown .box.box-active{
    background-color: #00BF85 !important;
    border:2px solid #00BF85 !important;

}

.position-text-box .text-position-dropdown .box {
    margin-bottom: 9px;
    border: 2px solid #707070;
    border-radius: 0;
    background-color: #636363;
    cursor: pointer;
    padding: 10px;
    color: #fff;
    transition: all .2s;
    flex: 0 0 33.33%;
    max-width: 24%;
}

.position-text-box .text-position-dropdown .box-wrapper {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
}

.remove-logo-switch-btn .form-check label{
    font-size: 12px;
    color:#8F8F8F;
}

.remove-logo-switch-btn .form-check .form-check-input{
    margin-top: 2px;
    margin-left: 10px;
    font-size: 24px;
    background-position: calc(100% - 90%) calc(100% - 0px);
}

.style-watermark .remove-logo-switch-btn .form-check .form-check-input {
    background-position: calc(100% - 90%) calc(100% - 0px);
}

.style-remove-cricle{
    width: 50px;
    height: 50px;
    background-color: #000;
    border-radius: 50%;
    border:1px solid #686868;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.style-remove-cricle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.dimmer-wrapper .dimmer-light h6{
    font-size: 14px;
}

.dimmer-switch-btn .remove-logo-switch-btn  .form-check-input{
    margin-top: 2px;
    font-size: 24px;
}

.swap-template-wrapper,
.dimmer-wrapper {
    padding: 10px;
    border-radius: 10px;
    background: #303030;
}

.swap-template-wrapper .colmn {
    margin-bottom: 20px;
    max-width: 48%;
}

.swap-template-wrapper .swap-template-box{
    background-color: #101010;
    height: auto;
}

.swap-template-wrapper h6{
    font-size: 14px;
}

.editor-tab-menu {
    /* width: 110%; */
    background: #232323;
}

/* editor text customization start */
.editor-main {
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    height: 100vh;
    /* width: 100%; */
}

/* .editor-container .colmn:nth-child(2) .colmn-preview-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
} */

.editor-sec .editor-container .colmn-2::-webkit-scrollbar-thumb {
    background: #00F5A0;
    border-radius: 10px;
}

.editor-sec .editor-container .colmn-2::-webkit-scrollbar-tracks {
    background: #0000;
}
.editor-sec .editor-container .colmn-2::-webkit-scrollbar {
    width: 10px;
}

.editor-preview-wrapper {
    /* box-shadow: 0px 0px 15px #00000087; */
    border-radius: 10px;
    /* overflow: hidden; */
    width: 100%;
    max-width: 70%;
    height: auto;
    margin: 0 auto;
}

/* .editor-sec .editor-container .colmn-1 {
    height: 100%;
} */

/* .editor-preview, */
.editor-part .tab-content,
.editor-sec .editor-container {
    height: 100%;
}

.editor-text-customization {
    padding: 10px;
}

#pills-media, #pills-images, #pills-video, #pills-medialibrary,
#pills-audio {
    overflow: hidden;
    /* height: 88%; */
}

.search-load > div video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.search-load > div {
    height: 100%;
    width: 100%;
}

.search-load-box, .scroll-wrapper, .upload-audio-box {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px 0 0;
}

.search-load-box .row {
    /* max-height: 80vh; */
    height: calc(100vh - 265px);
}

.scroll-wrapper > .row {
    /* height: 75vh; */
    height: calc( 100vh - 295px );
}

.upload-audio-box > .row {
    height: 620px;
}

.editor-part .tab-pane {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 0;
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.editor-part .tab-pane, .search-load-box,
.scroll-wrapper, .upload-audio-box {
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.editor-part .tab-pane::-webkit-scrollbar-thumb,
.search-load-box::-webkit-scrollbar-thumb,
.scroll-wrapper::-webkit-scrollbar-thumb,
.upload-audio-box::-webkit-scrollbar-thumb {
    background: #00f5a0;
    border-radius: 10px;
}

.editor-part .tab-pane::-webkit-scrollbar-track,
.search-load-box::-webkit-scrollbar-track,
.scroll-wrapper::-webkit-scrollbar-track,
.upload-audio-box::-webkit-scrollbar-track {
    background: #0000;
}

.editor-part .tab-pane::-webkit-scrollbar,
.search-load-box::-webkit-scrollbar,
.scroll-wrapper::-webkit-scrollbar,
.upload-audio-box::-webkit-scrollbar {
    width: 5px;
}

.editor-text-customization .editor-text-family {
    position: relative;
    flex: 0 0 70%;
    max-width: 70%;
}

.editor-text-customization .editor-text-weight-size {
    flex: 0 0 29%;
    max-width: 29%;
}

.editor-text-customization select ~ .icon {
    position: absolute;
    top: 50%;
    right: 15px;
    line-height: 0;
    transform: translateY(-50%);
}

.editor-text-customization  .editor-input-dark {
    color: #fff !important;
    padding: 5px ​10px 5px;
    border-radius: 4px !important;
    border: 1px solid #707070;
    background-color: #0C0C0C !important;
    height: auto;
    box-shadow: none;
    resize: none;
    height: 43px!important;
    letter-spacing: 1px;
}

.editor-text-customization select {
    padding: 7px 12px;
    font: 500 14px/normal "Poppins",sans-serif;
}

.editor-text-customization .txt-edit-btn {
    flex: 16.6666%;
    max-width: 16%;
}

.editor-text-alignment .txt-edit-btn {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 36px;
    height: 36px;
}

.editor-text-customization select:focus,
.editor-text-customization input:focus,
.editor-text-customization button.active {
    border-color: #00FFB1;
}

.editor-text-customization #t-lineheight {
    padding-left: 32px;
}

.editor-text-customization .input-group-prepend {
    position: relative;
    flex: 0 0 31%;
    max-width: 31%;
}

.editor-text-align .txt-edit-btn {
    flex: 0 0 17.25%;
    max-width: 16.5%;
}

.editor-text-customization .input-group-prepend .icon {
    position: absolute;
    top: 45%;
    left: 10px;
    z-index: 1;
    transform: translateY(-50%);
    color: #747474;
}

.editor-font-color #tColor-hex,
.editor-font-color #tBg-hex {
    padding-left: 40px;
}

.editor-font-color .input-color {
    position: relative;
    flex: 0 0 50%;
    max-width: 48.7%;
}

.editor-font-color .editor-rgb-wrapper {
    flex: 0 0 16%;
    max-width: 16%;
}

.editor-font-color #t-color-btn,
.editor-font-color #t-bg-btn {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 8px;
    border-radius: 3px;
    border: 2px solid hsla(0,0%,100%,.5);
    z-index: 1;
    transform: translateY(-120%);
    transition: none !important;
}
.editor-font-color .swatch, .editor-font-color .no-color {
    height: 35px;
    border: 0;
    border-radius: 2px;
    padding: 0;
    flex: 0 0 9%;
    max-width: 8.8%;
    margin: 0px 1px 1px 0;
    overflow: hidden;
    outline: none !important;
    position: relative;
}

.editor-font-color .no-color::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 2px;
    height: 50px;
    background: #a2000f;
    transform: rotate(45deg);
    margin: auto;
}

.editor-wrapper {
    padding: 10px 15px;
}

.editor-font-color {
    background: #303030;
    border-radius: 10px;
}

/* editor text customization End */

/* media tab css start */
.media-menu-box .nav .nav-item {
    flex: 0 0 24.9%;  /* 0 0 33.33% */
    text-align: center;
}

.media-menu-box .nav .nav-item:nth-child(2),
.audio-box-menu .nav .nav-item:nth-child(2) {
    border-left: 1px solid #3e3e3e;
    border-right: 1px solid #3e3e3e;
}

/* ---------------- */
.media-menu-box .nav {
    border-radius: 100px;
    overflow: hidden;
    padding: 0!important;
}

.media-menu-box .nav-item .nav-link{
    color: #737373;
    background-color: #000;
    padding: 12px 0px;
    font-size: 12px;
    font-family: var(--poppins);
}



.media-menu-box .nav-item .nav-link.active{
    background-color: #10CA9C;
    color: #fff;
}

.media-search-box .input-group .input-group-prepend .input-group-text{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #fff;
    border:1px solid #fff;
    color:#979797;
    cursor: pointer;
}
.media-search-box .input-group input{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border:none;
    height: 45px;
}

.media-search-box .input-group-prepend{
    margin-left: 0px;
}

.media-search-box input:focus{
    box-shadow: none;
}

.search-load {
    background-color: #101010;
    height: 100px;
    border: 1px solid #707070;
    overflow: hidden;
}

.search-load img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: top;
    z-index: 1;
}

.search-load-wrapper-blur-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.search-load-wrapper-blur-bg img {
    position: absolute;
    top: 0;
    object-fit: cover;
    /*filter: blur(10px);*/
    width: 100%;
}

.search-load-box .search-load-wrapper-blur-bg {
    overflow: hidden;
}

.search-load-box .search-load-wrapper-blur-bg img{
    transform: scale(1.5);
}

.search-load-box .colmn {
    margin-bottom: 20px;
    max-width: 48%;
    padding: 0;
}

.search-load .search-img-result {
    z-index: 1;
}

.media-upload-box button{
    /* background:linear-gradient(to right, #10CA9C , #a72ce3); */
    background: #10ca9c;
    background: -moz-linear-gradient(left,  #10ca9c 0%, #a72ce3 50%, #10ca9c 100%);
    background: -webkit-linear-gradient(left,  #10ca9c 0%,#a72ce3 50%,#10ca9c 100%);
    background: linear-gradient(to right,  #10ca9c 0%,#a72ce3 50%,#10ca9c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10ca9c', endColorstr='#10ca9c',GradientType=1 );
    background-size: 200% auto;
    transition: .5s ease-in-out all;
    border:none;
    color:#fff;
    border-radius: 5px;
    height: 100%;

}

.hover-effect {
    background-position: right!important;
}

.media-upload-box{
    position: relative;
    height: 45px;
}

.media-upload-box .custom-file{
    position: absolute;
    top:0px;
    height: 100%;
}

.media-upload-box .custom-file .custom-file-input{
    opacity: 0;
    background-color: red;
    border-radius: 30px;
    font-size: 2px;
    position: absolute;
    top:0px;
    cursor: pointer;
    height: 100%;
}

/* media start css */


.credites-wrapper textarea{
    background-color: #0C0C0C;
    border:1px solid #707070;
}

.credites-wrapper textarea:focus{
    background-color: #0C0C0C;
    box-shadow: none;
}

.credites-wrapper textarea.form-control{
    color:#787878;
    font-size: 14px;
}


.credites-wrapper label{
    font-family: var(--poppins);
}

.credites-upload-box .outer-images{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.credites-upload-box .outer-images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.credites-uplaod {
    position: relative;
    height: 100px;
    background-color: #171717;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px dashed #707070
}

.credites-uplaod h6{
    font-size: 14px;
    color:#8F8F8F;
}

.credites-uplaod .custom-file {
    position: absolute;
    top: 0;
}


.credites-uplaod  .custom-file-input {
    position: absolute;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
    background: red;
    height: 261%;
    font-size: 1px;
    cursor: pointer;
}

.credites-upload-box {
    padding: 20px;
    background: #303030;
    border-radius: 10px;
}

.credites-upload-box label {
    font-size: 14px;
    color: #737373;
}

.remove-outer-logo  .form-check-input {
    margin-left: 20px;
    position: unset;
    font-size: 24px;
}

.outro-template-box{
    padding: 20px;
    background: #303030;
    border-radius: 10px;
}

.outro-template-box h6{
    font-family: var(--poppins);
    font-size: 14px;
}

.outer-images-box{
    background-color: #000000;
    height: auto;
}

.outer-template-wrapper .colmn {
    margin-bottom: 20px;
}

/* audio editor css start */
#music-library-nav {
    border-radius: 0;
}

#music-library-nav .nav-item {
    flex: 0 0 50%;
    border-right: none;
    padding: 0px 15px!important;
}

#music-library-nav .nav-item .nav-link {
    background: none;
    border: none;
}

#music-library-nav .nav-item .nav-link.active {
    border-bottom: 2px solid  #10ca9c;
}

#pills-sonority.sonority-wrapper .card {
    background: #6326ac;
    background: -moz-linear-gradient(top,  #6326ac 0%, #7677f6 100%);
    background: -webkit-linear-gradient(top,  #6326ac 0%,#7677f6 100%);
    background: linear-gradient(to bottom,  #6326ac 0%,#7677f6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6326ac', endColorstr='#7677f6',GradientType=0 );
    border: 0;
}

#pills-sonority.sonority-wrapper .card .card-footer {
    background: #570ea9;
}

.card-header-uploaded {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
}

.card-icon-delete {
    cursor: pointer;
    top: -3px;
    filter: drop-shadow(0px 0px 10px rgba(0 0 0 / 100%));
}

.active-audio .active-audio-wrapper i {
    color: #00f5a0;
}

.active-audio .active-audio-wrapper {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000b3;
    z-index: 1;
    border-radius: 7px;
}

.active-audio-wrapper {
    display: none;
}

.audio-box-menu .tab-pane {
    padding: 10px;
}

.audio-box-menu .nav .nav-item {
    flex: 0 0 33.33%;
    text-align: center;
}

.audio-music-box .card-body img,
.upload-box-card .card-body img {
    width: 45%;
}

.audio-box-menu .tab-pane .colmn,
.audio-music-box .card .card-footer .col-md-9 {
    padding: 0 5px;
}

.audio-box-menu .tab-pane .row .colmn {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-bottom: 10px;
}

.audio-box-sec .audio-box-menu .nav-item .nav-link.active {
    background-color: #10CA9C;
    color: #fff;
}

.audio-box-menu .nav {
    padding: 0!important;
    border-radius: 100px;
    overflow: hidden;
}

.audio-box-sec .audio-box-menu .nav-item .nav-link{
    background-color:#000;
    color: #737373;
    font-size: 12px;
    font-family: var(--poppins);
    padding: 12px 0;
}

.horigental-line {
    border: 1px solid #BEBEBE;
    width: 100%;
    display: block;
}

.audio-music-box .card{
    background: linear-gradient(to bottom , #00EDBC , #009DB0);
    border-radius: 10px;
    transition: .4s ease-in-out;
}

.audio-music-box .card.active {
    border: 3px solid #a72ce2;
}

.audio-music-box .card:hover{
    transform: scale(1.05);
    transition: .4s ease-in-out;
}

.audio-music-box .card .card-footer{
    background-color:#54D1CE ;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}


.audio-music-box .card .card-title {
    padding: 10px;
    font-size: 12px;
}

.audio-music-box .card-body{
    padding: 0px;
}

.card-music-name h6 {
    font-size: 9px;
}

.card-play-icon .fa-2x {
    font-size: 1.2em;
}

.tts-box-wrapper .audio-slide-box input {
    height: 40px;
    background: #0C0C0C;
    color: #fff;
    border-color: #626262;
}

.audio-slide-box  .form-group select{
    background-color:#0C0C0C;
    color:#fff;
    box-shadow: none;
    border-color: #707070;
    font-size: 14px;
}

.audio-language-box  .form-group select{
    background-color:#0C0C0C;
    color:#fff;
    box-shadow: none;
    border-color: #707070;
    font-size: 14px;
    padding: 7px 0px 10px 12px;
}
.audio-language-box .form-group label{
    font-size: 12px;
    font-family: var(--poppins);
}
.audio-voice-box .form-group label{
    font-size: 12px;
    font-family: var(--poppins);
}

.audio-slide-box .form-group label{
    font-size: 12px;
    font-family: var(--poppins);
}

.audio-language-box  .form-group .form-select,
.audio-voice-box .form-group .form-select{
    padding-right: 0px;
    height: 40px;
}

.audio-voice-box .form-group select{
    background-color:#0C0C0C;
    color:#fff;
    box-shadow: none;
    border-color: #707070;
    font-size: 14px;
    padding: 7px 0px 10px 12px;
}

.audio-add-text-box textarea{
    background: #0C0C0C;
    border-color: #707070;
    font-size: 14px;
}

.audio-add-text-box textarea:focus{
    background: #0C0C0C;
    box-shadow: none;
}

.audio-add-text-box textarea.form-control{
    color:#787878;
}

.tts-box-wrapper{
    background: #303030;
    border-radius: 10px;
    padding: 10px;
    height: calc(100vh - 230px);
    overflow-x: hidden;
    overflow-y: auto;
}

.tts-box-wrapper label {
    color: #737373;
}

.audio-preview-btn button{
    background: #ffffff;
    background: -moz-linear-gradient(left,  #ffffff 0%, #ffffff 50%, #10ca9c 50%, #a72ce3 100%);
    background: -webkit-linear-gradient(left,  #ffffff 0%,#ffffff 50%,#10ca9c 50%,#a72ce3 100%);
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 50%,#10ca9c 50%,#a72ce3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#a72ce3',GradientType=1 );
    background-size: 202%;
    background-position: 99% 50%;
    border:none;
    color:#fff;
    border-radius: 5px;
    height: 45px;
    transition: .3s ease-in-out;
}

.btn-change6:hover {
    background-position: 0%!important;
    color:#2fcac9!important;
}

.btn-change6:focus {
    color: #fff;
}


.audio-save-use-btn button{
    background: #ffffff;
    background: -moz-linear-gradient(left,  #ffffff 0%, #ffffff 50%, #00ffba 50%, #3b91cb 100%);
    background: -webkit-linear-gradient(left,  #ffffff 0%,#ffffff 50%,#00ffba 50%,#3b91cb 100%);
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 50%,#00ffba 50%,#3b91cb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#3b91cb',GradientType=1 );
    background-size: 202%;
    background-position: 99% 50%;
    border:none;
    color:#fff;
    border-radius: 5px;
    height: 45px;
    transition: .3s ease-in-out;
}

.audio-save-use-btn button:hover{
    color:#2fcac9;
}



.audio-library-boxs .audio-upload-btn button{
    border-radius: 5px;
    /* background: linear-gradient(to right, #10CA9C , #a72ce3); */
    background: #10ca9c;
    background: -moz-linear-gradient(left,  #10ca9c 0%, #a72ce3 50%, #10ca9c 100%);
    background: -webkit-linear-gradient(left,  #10ca9c 0%,#a72ce3 50%,#10ca9c 100%);
    background: linear-gradient(to right,  #10ca9c 0%,#a72ce3 50%,#10ca9c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10ca9c', endColorstr='#10ca9c',GradientType=1 );
    background-size: 200% auto;
    transition: .5s ease-in-out all;
    border:none;
    height: 45px;
    color:#fff;
}

.audio-upload-btn{
    position: relative;
}

.editor-wrapper .editor-text-color textarea.form-control {
    height: 150px!important;
}

.audio-library-boxs  .audio-upload-btn .custom-file-input {
    position: absolute;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
    background: red;
    border-radius: 30px;
    font-size: 1px;
    height: 45px;
    cursor: pointer;
}

.audio-library-boxs .audio-upload-btn .custom-file {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
    top: 0;
    height: 100%;
}


.upload-box-card .card{
    background: linear-gradient(to bottom , #B94EFF , #DB438F);
    border:none;
    border-radius: 10px;
    transition: .4s ease-in-out;
}

.upload-box-card .card:hover{
    transform: scale(1.05);
    transition: .4s ease-in-out;
}

.upload-box-card .card-footer{
    background: #B240E6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.upload-box-card .card-title {
    padding: 10px;
    font-size: 12px;
}

.upload-box-card .card-body{
    padding: 0px;
}

.card-play-icon{
    cursor: pointer;
    display: flex;
    align-items: center;
}


.remove-logo-switch-btn .form-check-input:checked{
    background: #C23636;
    box-shadow: none;
    border-color:#C23636;
}

.remove-logo-switch-btn  .form-switch .form-check-input:checked {
    background-position: calc(100% - 10%) calc(90% - 1px)!important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    box-shadow: none;
}


.remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    box-shadow: none;
    background-position: calc(100% - 10%) calc(90% - 1px)!important;
}



.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked{
    background: #00BF85;
    box-shadow: none;
    border-color:#00BF85;
}


.dimmer-switch-btn  .remove-logo-switch-btn  .form-switch .form-check-input:checked {
    background-position: right center !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    box-shadow: none;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    box-shadow: none;
}


/* Video Tiles Start */
.slider-9-16 .video-tiles-controls,
.slider-1-1 .video-tiles-controls {
    flex-wrap: wrap;
}

.video-tiles-controls {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(to bottom, #000000d4 100%,#fff0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#00ffffff',GradientType=0 );
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
}

.slider-9-16 .video-tiles-controls,
.slider-1-1 .video-tiles-controls {
    height: 21px;
    padding-top: 16px;
}

.video-tiles-controls .refresh-delete {
    position: absolute;
    right: 2px;
    top: 2px;
}

.slider-9-16 .video-tiles-controls .refresh-delete,
.slider-1-1 .video-tiles-controls .refresh-delete {
    position: absolute;
    right: 0;
    top: -2px;
    left: 0;
}

.video-tiles-controls a {
    margin: 0 2px;
}

.video-tiles-controls .cursor-pointer i {
    color: #00f5a0;
    filter: drop-shadow(2px 4px 6px black);
    font-size: 12px;
}

.video-tiles-controls .cursor-pointer i.fa-crop {
    position: absolute;
    top: 9px;
}

.slider-9-16 .cursor-pointer i,
.slider-1-1 .cursor-pointer i {
    font-size: 8px;
}

.video-tiles-time-duration input:focus {
    outline: none;
    border: 1px solid #01eb9a;
    border-radius: 5px;
}

.video-tiles-time-duration input {
    width: 50px;
    /* position: absolute; */
    left: 0px;
    border: 0;
    background: #0000;
    color: #fff;
    top: -1px;
    font-size: 9px;
}

.slider-1-1 .video-tiles-time-duration input {
    width: 30px;
}

.slider-9-16 .video-tiles-time-duration input {
    width: 12px;
}

.slider-9-16 .video-tiles-time-duration input,
.slider-1-1 .video-tiles-time-duration input {
    font-size: 8px;
}

.video-tiles-time-duration input::-webkit-outer-spin-button,
.video-tiles-time-duration input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.video-tiles-time-duration input[type=number] {
  -moz-appearance: textfield;
}

.video-tiles-time-duration {
    display: flex;
    align-items: center;
}

.video-tiles-time-duration span {
    font-size: 9px;
    margin-left: 5px;
    color: #fff;
    letter-spacing: 1px;
}

.slider-9-16 .video-tiles-time-duration span,
.slider-1-1 .video-tiles-time-duration span {
    font-size: 8px;
    margin-left: 1px;
    letter-spacing: 0.5px;
}



/* Template1_2 wrapper preview start */


/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
/* Please! Dont touch this! Dont change the position-absolute into positio relative! it causes the template break!  
*/
.template-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.template-wrapper *{
    position: relative;
  }
/* Please! Dont touch this! Dont change the position-absolute into positio relative! it causes the template break!  */
/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

/* Template1_2 wrapper preview end */

/* Aspect Ratio Start */
.preview-wrapper {
    height: 100%;
    position: relative;
}

.ar-16-9 {
    max-width: 100%;
    max-height: 100%;
}

.ar-9-16 {
    max-width: 171px;
    max-height: 304px;
}

.ar-1-1 {
    max-width: 310px;
    max-height: 310px;
}

.ar-4-3 {
    max-width: 90%;
    max-height: 100%;
}

.ar-3-4 {
    max-width: 50%;
    max-height: 100%;
    margin: 0 auto;
}

.slider-16-9, .slider-16-9 .box-ar-loader {
    width: 150px!important;
    height: 70px!important;
    margin: 0 auto!important;
}

.slider-9-16, .slider-9-16 .box-ar-loader {
    width: 50px!important;
    height: 70px!important;
    margin: 0 auto!important;
}

.slider-1-1, .slider-1-1 .box-ar-loader {
    width: 70px!important;
    height: 70px!important;
    margin: 0 auto!important;
}

.slider-3-4, .slider-3-4 .box-ar-loader {
    width: 60px!important;
    height: 70px!important;
    margin: 0 auto!important;
}

.slider-4-3, .slider-4-3 .box-ar-loader {
    width: 90px!important;
    height: 70px!important;
    margin: 0 auto!important;
}

.upload-audio-primary{
    height: calc(100vh - 300px);
    overflow-x: hidden;
    overflow-y: auto;
}
.alert-height{
    height: fit-content !important;
}