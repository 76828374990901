@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (max-width: 575.98px) {

    .position-text-box{
        margin-top: 10px !important;
    }
    .integration-box li{
        margin-top:20px;
    }

    .facebook-connection{
        margin-top: 20px;
    }
    .template-cover{
        margin-top: 20px;
    }

    .webinar-sec{
        padding: 69px 10px;
    }

    .form-box{
        padding: 20px 10px;
    }
    .banner-content {
        padding: 40px 0px;
    }
    .banner-content h1 {
        font-size: 50px;
    }

    .project-header-name .project-item h4 {
        font-size: 20px;

    }
    .project-header-name .project-conut h6{
        font-size: 20px;
    }

    .banner-content .watch-demo-btn button{
        padding: 15px 50px;
    }
    .banner-video-project .video-project a span {

        margin-left: 0 !important;
    }



    .banner-video-project .video-project a{
        font-size: 14px;
    }

    .banner-video-project .video-project h5 {
        font-size: 20px;

    }

    .player-box{
        margin-top: 20px;
    }

    .important-topics{
        margin-top: 20px;
    }

    .banner-content p {
        width: 100%;
    }
    .banner-items{
        padding: 40px 0px;
    }

    .dashborad-sec-3{
        padding: 40px 0px;
    }

    .imgsizeleft{
        margin-top: 30px;
    }

    .dashborad-sec-3-content {
        margin-top: 30px;
    }
    header .dropdown-menu {
        left: 0 !important;
    }

    .video-card{
        margin-top: 30px;
    }

    .login-box{
        padding: 40px 0px;
    }

    .login-form{
        padding: 20px;
        margin-top: 20px;
    }
    .login-form p{
        font-size: 14px;
    }

    .login-form h4 {
        font-size: 17px;
    }
    .login-content h5 {
        font-size: 20px;
    }

    .project-input-box{
        margin-right: 0px;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }


    .search-select{
        flex-direction: column;
        justify-content: start;
    }

    .search-select .search-box{
        margin-top: 20px;
    }

    .template-name h4{
        font-size: 14px;
    }

    .template-number h6{
        font-size: 14px;
    }

    .select-box {
        margin-top: 15px;
    }
    .support-content {
        margin-top: 30px;
    }



    .webiner-content {
        margin-top: 20px;
    }


}


@media (max-width: 767.98px) {
    .colmn.w-colmn.col-md-8{
        min-width: 100% !important;
      }

    .position-text-box{
        margin-top: 10px !important;
    }

    .imgsizeleft{
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }
    .template-cover{
        margin-top: 20px;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }

    .player-box{
        margin-top: 20px;
    }

    .important-topics{
        margin-top: 20px;
    }
    .support-content {
        margin-top: 30px;
    }
    .webiner-content {
        margin-top: 20px;
    }
    .project-button{
        flex-direction: column !important;
        margin-top: 20px;
        justify-content: center;
    }
    .project-button .project-create-btn{
        margin-top: 20px;
    }

}

@media (max-width: 991.98px) {
    .imgsizeleft{
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }

    .login-form{
        margin-top: 30px;
    }
    .template-cover{
        margin-top: 20px;
    }

    .video-card{
        margin-top: 30px;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }

    .project-button {
        margin-top: 30px;
    }

    .banner-items{
        padding: 60px 0px;
    }
    .banner-content{
        padding: 60px 0px;
    }
    .dashborad-sec-3{
        padding: 30px 0px;
    }

    .position-text-box {
        min-width: 123px !important;
        max-width: 123px !important;
        margin: auto;
    }
    .player-box{
        margin-top: 20px;
    }

    .important-topics{
        margin-top: 20px;
    }
    .support-content {
        margin-top: 30px;
    }


}





@media (max-width: 1199.98px) {
    .login-content h1{
        font-size: 50px;
    }

    .template-name h4{
        font-size: 18px;
    }

    .position-text-box {
        min-width: 123px;
        max-width: 123px;
    }

    .remove-logo-switch-btn .form-check label{
        font-size: 10px;
    }

    .remove-logo-switch-btn .form-check .form-check-input{
        margin-left: 8px;
        /* margin-top: 10px !important; */
        /* font-size:10px !important; */
    }
    .dimmer-switch-btn  .remove-logo-switch-btn .form-check-input{
        margin-top: 2px;
        margin-left: -30px;
        /* font-size: 10px !important; */
    }

    .integration-box li{
        margin-top:20px;
    }





}



@media (max-width:1300px){

    .position-text-box{
        min-width: 122px;
        max-width: 122px;
    }




    /* .remove-logo-switch-btn .form-check .form-check-input{
        margin-left: 8px;
        font-size:20px;
    } */
    .dimmer-switch-btn  .remove-logo-switch-btn .form-check-input{

        font-size: 20px;
    }


}




@media (min-width:1400px){

    .save-render-sec{
        width: 100%;
    }


    .editor-preview{
        width: 100%;
    }

    .render-projectbtn {
        margin-right: 0px;
    }

    .editor-tab-menu{
        width: 100%;
    }

}



@media (min-width:1500px){
    .container-fluid{
        max-width: 1400px;
    }

    .render-projectbtn{
        margin-right: 0px !important;
    }

    .secarchicone-part #searchimg{
        left: 0%;
    }
}
:root {

  --poppins: 'Poppins', sans-serif;
  --Montserrat: 'Montserrat', sans-serif;
}



@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

}

button {
  cursor: pointer !important;
}


a:hover {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}


header .navbar {
  /*background-image: url('../images/Navbg.png');*/
  background-size: cover;
  background-position: center;
  height: 70px;
}


header .navbar .navbar-nav .active {
  border-bottom: 1px solid #00F5A0;
}

.navmenuid {
  display: flex;
  align-items: center;
  justify-content: center;
}


.belldropdown button {
  background: linear-gradient(to right, #01D084, #0482D8);
  padding: 7px 20px !important;
  border-radius: 30px;
  display: inline-block;
}

.loader {
  width: 80px;
  height: 80px;
  border: 3px var(--secondary) solid;
  border-radius: 50%;
  -webkit-animation: rotation 1s ease-in-out infinite;
          animation: rotation 1s ease-in-out infinite;
  text-align: center;
}

.loader-sec {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* background-color: #ffffff; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader::after {
  width: 15px;
  height: 15px;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  border-radius: 100%;
  position: absolute;
  content: "";
}

.belldropdown .dropdown-item {
  white-space: normal !important;
  background-color: #efeeee94;
  margin: 5px 0px;
  font-size: 14px;
}


.navmenuid .text h6 {
  font-size: 12px;
  color: #02BD99;
}

.navmenuid .text {
  color: #fff;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  margin-left: 10px;
}

header .dropdown-menu {
  position: absolute !important;
  left: -105px !important;
  top: 44px !important;
}

header .navbar ul li a {
  color: #fff !important;
  font-family: 'Poppins', sans-serif !important;
  font-family: var(--poppins) !important;
  transition: .2s ease-in-out;
  padding: 0px 0px;
}

header .navbar ul li:hover a {
  color: #00FFC2 !important;
  transition: .2s ease-in-out;
}

header .navbar ul li {
  margin-right: 30px;
}


header button#dropdownMenuButton span {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;

  cursor: pointer;
  transition: .2s ease-in-out;
  overflow: hidden;

}

.dropdown-menu.show {
  display: block;
  transition: .2s ease-in-out;
}

header .dropdown-toggle::after {
  display: none !important;
}

header .dropdown button {
  padding: 0;
  border: none;
  background-color: transparent;
}

header .dropdown button:focus {
  box-shadow: none;
  outline: none !important;
}

header .dropdown-item:hover {
  background-image: linear-gradient(to right, #00C2FF, #00E9D8, #00FFC2);
}

.bashboard-banner .banner-items-list {
  background: linear-gradient(0.25turn, #842DD1, #AB2DBA, #E22D9A);
  border-radius: 15px;
  padding: 5px;
}

.bashboard-banner .banner-items p {
  color: #fff !important;
  font-size: 14px;
}

.bashboard-banner .banner-items a {
  margin-top: 15px;
}

.bg2 {
  background: linear-gradient(0.25turn, #2C7180, #1EAB91, #14D29D) !important;
}

.bg3 {
  background: linear-gradient(0.25turn, #2370BE, #4BB1CE, #4BB4CF) !important;
}

.banner-content {
  padding: 136px 0px;
}

.banner-items {
  padding: 136px 0px;
}


.bashboard-banner {
  position: relative;
  margin-top: 70px;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spin {
  -webkit-animation: spin 1000ms linear infinite;
          animation: spin 1000ms linear infinite;
}

.bashboard-banner::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #15191E;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.bashboard-banner {
  /*background-image: url('../images/Mask\ Group\ 3.png');*/
  background-size: cover;
  background-position: top center;
  width: 100%;

  background-repeat: no-repeat;

}

.banner-content .watch-demo-btn button {
  background-image: linear-gradient(to right, #00C2FF, #00E9D8, #00FFC2);
  padding: 15px 58px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  cursor: pointer;
  overflow: hidden;
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}



.banner-content .watch-demo-btn button span {
  margin-right: 5px;

}


.banner-content h4 {
  font-size: 39px;
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
  font-weight: 300;
}

.banner-content h1 {
  font-size: 73px;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
}

.banner-content h1 span {
  color: #00F5A0;
}

.banner-content p {
  width: 544px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-size: 18px;
  font-weight: 300;
}

.dashborad-sec-3 {
  position: relative;
  overflow: hidden;
  padding: 80px 0px;
  background-color: #15191E;
}

.banner-video-project {
  background-color: #15191E;
  padding: 40px 0px;
}

.banner-video-project .video-project h5 {
  font-size: 40px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.banner-video-project .video-project h5 span {
  color: #00F8B8;
}


.banner-video-project .video-project a span {
  color: #fff;
  margin-left: 20px;
}

.video-card {
  background-image: linear-gradient(to bottom, #00FDC3, #2DA8C7);
  border-radius: 10px;
  padding-top: 1px;
}


.project-create-btn button:hover {
  color: #2fcac9 !important;
}


.ClipsReel-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .8s ease-in-out;
  background-color: #000000a3;
  border-radius: 10px;
  -webkit-clip-path: circle(30.3% at 100% 0);
          clip-path: circle(30.3% at 100% 0);
}

.ClipsReel-overlay ul {
  height: auto;
}

.ClipsReel-overlay ul li a span {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vidoe-play-icon span i {
  color: #fff !important;
}


.ClipsReel-overlay ul li:hover i {
  color: #00F5A0 !important;
}

.ClipsReel-overlay ul li:hover a {
  color: #00F5A0;
}

.ClipsReel-overlay ul li a {
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ClipsReel-overlay ul li a i {
  font-size: 18px;
  color: #000;
}


.video-img:hover .ClipsReel-overlay {
  opacity: 1;
  transition: .8s ease-in-out;
  -webkit-clip-path: circle(141.4% at 100% 0);
          clip-path: circle(141.4% at 100% 0);
}

.video-card .video-img {
  border-radius: 5px;
  position: relative;
  margin: 10px;
  text-align: center;
}

.video-card .video-title {
  padding: 10px;
  padding-top: 0px;
}

.video-card .video-title .vidoe-play-icon span {
  cursor: pointer;

}

.video-name {
  background-color: #B1FCFF;
  width: 100%;
  padding: 5px 10px;
  border-radius: 30px;
}

.video-name p {
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-size: 14px;
  padding-left: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
}

.vidoe-play-icon span img {
  width: 45px;
}

.banner-video-project .video-project a {
  color: #fff;
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover span {
  color: #00F5A0;
}





.dashborad-sec-3-content h4 {
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}


.dashborad-sec-3-content h4:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.dashborad-sec-3-content h6 {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #7BFFE9;
  text-transform: uppercase;

}

.dashborad-sec-3-content p {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 200;
}

.imgsizeleft {
  margin-left: 50px;
}

.imgsizeright {
  margin-left: -320px;
}


.logoimg {
  /*background-image: url('../images/BG.png');*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}



.login-box {
  padding: 0px 0px 30px;
  min-height: calc(100vh - 132px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-content h1 {
  font-size: 55px;
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
  font-weight: 800;
}

.login-content h1 span {
  color: #00F8B8;
}

.login-content p {
  width: 90%;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 300;
  margin: auto;
  color: #C1C1C1;
}

.login-content h5 {
  font-size: 29px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}



.login-form {
  padding: 50px;
  background-color: #101010;
  border-radius: 25px;
  border: 1px solid#00E5FF !important;
}

.login-form h4 span {
  color: #00F8B8;
}

.login-form h4 {
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
  font-size: 26px;

}

.login-form button {
  background-image: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  color: #fff;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  cursor: pointer;
}

.login-form button:focus {
  color: #00b889 !important;
  background-color: #fff !important;
}

.login-form button:focus {
  background-image: none !important;
}

.login-form .forgot a {
  color: #C3C3C3 !important;
}

.login-form .forgot span:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.login-form .form-group input {
  background-color: transparent !important;
  border-color: #34B8CC !important;
  border-radius: 10px !important;
}

.login-form .form-group input:focus {
  box-shadow: none !important;
}

.login-form .form-group .form-control {
  color: #fff !important;
}


.login-form a span {
  color: #5EACB1;
  margin-left: 5px;
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li a {
  color: #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li a:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.footer-copyright p a:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li+li {
  margin-left: 40px;
}

.footer .footer-copyright p {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.footer .footer-copyright p a {
  transition: .2s ease-in-out;
}

.footer {
  background-color: #0000007a;
  padding: 10px 0px;
}



.footerbg {
  background-color: #000000;
  padding: 15px 0px;
}




.banner-items .banner-items-list .col-md-3 img {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: .4s ease-in-out;
}

/* .banner-items .banner-items-list{
    transform: scale(1);
    transition: .4s ease-in-out;
}

 .banner-items .banner-items-list:hover{
    transform: scale(1.05);
    transition: .4s ease-in-out;
}  */

.banner-items .banner-items-list:hover .col-md-3 img {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition: .4s ease-in-out;
}



.project-banner {
  padding: 20px 0px;
  background-color: #15191E;
}


.project-header-name .project-conut h6 {
  color: #A2A2A2;
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  margin-left: 30px !important;
  position: relative;
  font-weight: 200;
}

.project-header-name .project-conut h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: #A2A2A2;
  top: 3px;
  left: -15px;
}



.project-header-name .project-item {
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 600;
}



.video-img img {
  width: 100%;
  height: 192px;
}


.project-button .project-create-btn button {
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-image: linear-gradient(to right, #36D6FF, #C867FF);
  border: none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 600;
  cursor: pointer;
}

.project-button .project-create-btn button:focus {
  background-color: #fff !important;
  background-image: none !important;
  color: #2fcac9 !important
}

.project-input-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.project-input-box .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.project-input-box .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.project-input-box .input-group input:focus {
  box-shadow: none !important;
}

.project-input-box ::-webkit-input-placeholder {
  color: #7A7A7A !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.project-input-box :-ms-input-placeholder {
  color: #7A7A7A !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.project-input-box ::placeholder {
  color: #7A7A7A !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.project-input-box {
  margin-right: 20px;
}

.project-banner .bottom-line {
  border-bottom: 1px solid #A2A2A2;
  padding-bottom: 20px;
  display: block;
  width: 98%;
  margin: auto;
}

.project-banner {
  min-height: 100vh;
  padding-bottom: 40px;
  padding-top: 90px;
}



.login-form .form-group label {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #C3C3C3;
  margin-left: 10px;
}

.login-form .form-group input {
  height: 50px;
}

.login-form ::-webkit-input-placeholder {
  font-size: 16px;
  color: #707070;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  padding-left: 10px;
}

.login-form :-ms-input-placeholder {
  font-size: 16px;
  color: #707070;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  padding-left: 10px;
}

.login-form ::placeholder {
  font-size: 16px;
  color: #707070;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  padding-left: 10px;
}

.btn-change6 {

  box-shadow: 0 0 1px #ccc;
  transition-duration: .3s;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;

}






.login-form button:hover {
  color: #00b889 !important;
}


.btn-change6:hover {
  box-shadow: 1100px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.medium-btn {

  box-shadow: 0 0 1px #ccc;
  transition-duration: .3s;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}


.medium-btn:hover {
  box-shadow: 300px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.btn-change7 {

  box-shadow: 0 0 1px #ccc;
  transition-duration: .3s;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}

.btn-change7:hover {
  box-shadow: 400px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}

.toggleupdate {
  background: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  padding: 7px 30px;
  border-radius: 30px;
  color: #fff;
  height: 45px;
}

.toggleupdate:hover {
  color: #2fcac9;
}

.cancl-btn a {
  color: #fff;
}

.btn-cancl {
  position: relative;
  z-index: 9999;
  display: inline-block;
}

.cancl-btn {
  background: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: #000;
  overflow: hidden;
  z-index: 2;
  position: relative;
}

.cancl-btn:hover {
  color: #fff !important;
}


.cancl-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to left, #00D3EB, #16B891);
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 30px;

}

.cancl-btn:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  color: #fff !important;
}


.fixed-me {
  /*background-image: url('../images/Navbg.png');*/
  background-size: cover;
  background-position: center;

  margin-bottom: 5px;
  position: fixed;
  top: 0;
  z-index: 8888;
  width: 100%;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}






.watch-demo-btn button {
  position: relative;
  z-index: 999;
}

.watch-demo-btn button::before {
  content: '';
  width: 0%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 30px;
  color: #00F5A0;
  transition: .3s ease-in-out;
  z-index: -1;
}

.watch-demo-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  border-radius: 30px;
}

.watch-demo-btn button:hover {
  color: #00b889 !important;
  transition: .4s ease-in-out;
}

.select-box .form-group {
  width: 260px;
  border: 1px solid #00FFD4;
  border-radius: 5px;
  margin-right: 20px !important;
}

.select-box .form-group select {
  border: none !important;
}

.select-box .form-group select:focus {
  box-shadow: none !important;
}

.select-box .form-group select {
  background-color: #101010;
}

.search-select .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.search-select .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.search-select .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.search-select .input-group input:focus {
  box-shadow: none !important;
}

.search-select ::-webkit-input-placeholder {
  color: #8b7676 !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.search-select :-ms-input-placeholder {
  color: #8b7676 !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.search-select ::placeholder {
  color: #8b7676 !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}



.premium-header {
  background-color: #303030;
  padding: 15px 0px;
  margin-top: 65px;
}

.select-box select {
  color: #fff !important;
}


.template-name h4 {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 400;
}



.template-number h6 {
  color: #A2A2A2;
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  margin-left: 30px;
  position: relative;
  font-weight: 200;
}


.template-number h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 18px;
  background-color: #A2A2A2;
  top: 4px;
  left: -15px;
}


.template-body {
  padding: 25px 0px;
  background-color: #171717;
  min-height: 100vh;
}

.template-names h6 {
  color: #DEDFDD;
}


/* .template-body .row {
    overflow-y: auto;
    height: 60vh;
} */




.template-body ::-webkit-scrollbar {
  width: 5px;
}

.template-body ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.template-body ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}


.customModal .modal-dialog .modal-body {
  height: 60vh;
}

/*.customModal .modal-dialog .modal-footer button{*/
/*    background:linear-gradient(to left , #00D3EB , #16B891);*/
/*    padding: 7px 25px;*/
/*    border:none;*/
/*    color:#fff;*/
/*    border-radius: 5px;*/
/*}*/

.customModal .modal-dialog .modal-footer button:hover {
  color: #2fcac9;
}

.customModal .modal-dialog .modal-footer button:focus {
  background: #fff;
  color: #2fcac9;
}

.customModal .modal-dialog .modal-footer {
  border: none;
}

.customModal .modal-scrollbar {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.modal-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.modal-scrollbar::-webkit-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px;
  height: 10vh !important;
}



/* .template-body ::-webkit-scrollbar-thumb:hover {
  background: #b30000;
} */



.modal-header .close {
  padding: 0px !important;
  margin: 0px !important;
}

.modal-header {
  width: 100%;
  margin: auto;
}

.modal-header .modal-title span {
  color: #00F5A0;
}

.modal-dialog {
  max-width: 1000px !important;
}




.modal-content {
  background-color: #0C0C0C;
  color: #fff !important;
}

.modal-title button span {
  color: #fff !important;
}

.facebook-item-box {
  border: 1px solid#707070;
  border-radius: 10px;
  background-color: #1C1C1C;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box:hover {
  border: 1px solid #00F5A0;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box h6 {
  color: #6A6A6A;
}

.facebook-item-box h5 {
  font-size: 14px;
}

.vidoe-play-icon {
  margin-top: 6px;
}



/* editor css start */


.editor-sec {
  background: #000000;
  min-height: 95vh;

  overflow: hidden;
}

.save-render-sec {
  background-color: #0c0c0c9e;
  padding: 10px;
}

/* .render-projectbtn {
    margin-right: 85px;
} */
.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}

.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}


.editor-part ::-moz-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part ::-moz-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part ::-moz-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px !important;
  height: 10px !important;
}








.editor-part::-webkit-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part::-webkit-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px !important;
  height: 10px !important;
}





.save-render-sec .add-campaign input {
  border-radius: 30px;
  border: none;
  background: #161616;
  width: 290px;
  height: 42px;
}

.save-render-sec ::-webkit-input-placeholder {
  color: #A5A5A5;
}

.save-render-sec :-ms-input-placeholder {
  color: #A5A5A5;
}

.save-render-sec ::placeholder {
  color: #A5A5A5;
}

.save-render-sec .add-campaign .form-control {
  color: #A5A5A5;
}

.save-render-sec .save-projectbtn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.save-render-sec .render-projectbtn button {
  background: linear-gradient(to right, #A72CE3, #CB3B71);
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.editor-tab-menu .nav-item .nav-link h6 {
  color: #fff;
  margin-top: 5px;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  margin-bottom: 0px;
}

.editor-tab-menu .nav-item {
  padding: 0px 10px;
}

.editor-tab-menu .nav-item .nav-link.active {
  background: #414141;
  border-radius: 10px;
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link.active h6 {
  color: #00FFB1;
}

.editor-tab-menu .nav-item .nav-link {
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link img {
  width: 17px;
}

.editor-tab-menu {
  padding: 10px;
}

.editor-preview {
  padding: 40px 0px;
  background: #333333;
  position: relative;
  /* width: 110%; */
}


.story-part-sec .story-para p {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #fff;
}

.story-part-sec .story-para {
  background-color: #242424;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid #707070;
}

.editor-preview-box {
  width: 656px;
  height: 369px;
  overflow: hidden;
  margin: auto;
}

.para-active {
  background-color: #2DA882 !important;
}

.slide-box ul li {
  width: 150px;
  height: 70px;
  border: 1px solid #434343;
  background-color: #101010;
  margin: 0px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #9A9A9A;
  border-radius: 10px;
  font-size: 14px;
}


.slide-sec {
  background-color: #0c0c0c;
  padding: 15px;
}

.slide-box .plus-icon i {
  color: #565656;
}

.start-end-time input {
  /* width: 100px; */
  background: transparent;
  border: none;
  color: #fff !important;
}

.start-end-time input:focus-visible {
  outline: none !important;
}

.start-end-time input.form-control {
  color: #fff;
}

.start-end-time ::-webkit-input-placeholder {
  color: #fff;
}

.start-end-time :-ms-input-placeholder {
  color: #fff;
}

.start-end-time ::placeholder {
  color: #fff;
}


.controls-box {
  background: #000;
  padding: 20px;
  width: 657px;
  margin: auto;
  position: relative;
  z-index: 999;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 3px 5px -1px #000;
}



.controls-box .start-end-time ul li>input:focus {
  box-shadow: none !important;
}

.save-projectbtn button:hover {
  color: #2fcac9;
}

.save-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #2fcac9 !important;
}

.add-campaign input:focus {
  box-shadow: none;
}

.render-projectbtn button:hover {
  color: #2fcac9;
}

.render-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #2fcac9 !important;
}

.style-upload-part {
  background: #303030;
  /* padding: 10px; */
  border-radius: 10px;
}

.style-upload-box {
  background-color: #171717;
  height: 77px;
  border: 1px dashed #707070;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style-upload-box .custom-file-input {
  position: absolute;
  top: 0;
  opacity: 0;
  background: red;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.style-upload-box h6 {
  font-size: 12px;
  margin-bottom: 0px;
  color: #8F8F8F;
}

.style-upload-box .custom-file {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.position-text-box {
  background: #303030;
  padding: 10px 10px;
  border-radius: 10px;
}

.position-text-box .text-position-dropdown label {
  font-size: 14px;
  margin-left: 14px;
}

.position-text-box .text-position-dropdown .box:hover {
  background-color: #00BF85;
  transition: all .2s;
  border: 2px solid #00BF85;
}

.position-text-box .text-position-dropdown .box.box-active {
  background-color: #00BF85 !important;
  border: 2px solid #00BF85 !important;

}

.position-text-box .text-position-dropdown .box {
  margin: 5px;
  border: 2px solid #707070;
  border-radius: 0;
  background-color: #636363;
  cursor: pointer;
  padding: 10px;
  width: 20px;
  height: 20px;
  color: #fff;
  transition: all .2s;
}

.position-text-box .text-position-dropdown .box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.remove-logo-switch-btn .form-check label {
  font-size: 14px;
  color: #8F8F8F;
}

.remove-logo-switch-btn .form-check .form-check-input {
  margin-top: 2px;
  margin-left: 10px;
  font-size: 24px;
}

.style-remove-cricle {
  width: 50px;
  height: 50px;
  background-color: #000;
  border-radius: 50%;
  border: 2px solid #686868;
}

.dimmer-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.form-duration {
  color: #fff !important;
  padding: 5px ​10px 5px;
  border-radius: 4px !important;
  border: 1px solid #707070;
  background-color: #0C0C0C !important;
  height: auto;
  box-shadow: none;
  resize: none;
  height: inherit;
  letter-spacing: 1px;
}

.dimmer-wrapper .dimmer-light h6 {
  font-size: 14px;
}

.dimmer-switch-btn .remove-logo-switch-btn .form-check-input {
  margin-top: 2px;
  font-size: 24px;
}

.swap-template-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.swap-template-wrapper .swap-template-box {
  background-color: #101010;
  height: 100px;
}

.swap-template-wrapper h6 {
  font-size: 14px;
}

.editor-tab-menu {
  /* width: 110%; */
  background: #232323;
}



/* media tab css start */




.media-menu-box .nav-item .nav-link {
  color: #fff;
  background-color: #3E3E3E;
  padding: 6px 20px;

  font-size: 13px;
  padding: 9px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  text-align: center;

}

.media-menu-box .nav-item .nav-link.active {
  background-color: #fff;
  color: #10CA9C;
  /*font-size: 13px;*/
  /*padding: 9px;*/
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  text-align: center;
}

.media-search-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #979797;
  cursor: pointer;
}

.media-search-box .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: none;

}

.media-search-box .input-group-prepend {
  margin-left: 0px;
}

.media-search-box input:focus {
  box-shadow: none;
}

.search-laod {
  background-color: #101010;
  height: 100px;
}

.media-upload-box button {
  background: linear-gradient(to right, #B376FF, #4AC6FF);
  border: none;
  color: #fff;
  border-radius: 30px;
}

.media-upload-box {
  position: relative;
}

.media-upload-box .custom-file {
  position: absolute;
  top: 0px;
}

.media-upload-box .custom-file .custom-file-input {
  opacity: 0;
  background-color: red;
  border-radius: 30px;
  font-size: 2px;
  position: absolute;
  top: 0px;
  cursor: pointer;
}

/* media start css */


.credites-wrapper textarea {
  background-color: #0C0C0C;
  border: 1px solid #707070;
}

.credites-wrapper textarea:focus {
  background-color: #0C0C0C;
  box-shadow: none;
}

.credites-wrapper textarea.form-control {
  color: #787878;
}


.credites-wrapper label {
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.credites-upload-box .outer-images {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
}

.credites-uplaod {
  position: relative;
  height: 100px;
  background-color: #171717;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #707070
}

.credites-uplaod h6 {
  font-size: 14px;
  color: #8F8F8F;
}

.credites-uplaod .custom-file {
  position: absolute;
  top: 0;
}


.credites-uplaod .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  height: 261%;
  font-size: 1px;
  cursor: pointer;
}

.credites-upload-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.remove-outer-logo .form-check-input {
  margin-left: -5px;
  font-size: 24px;
  margin-top: 0px;
}

.outro-template-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.outro-template-box h6 {
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.outer-images-box {
  background-color: #000000;
  height: 100px;
}


/* audio editor css start */



.audio-box-sec .audio-box-menu .nav-item .nav-link.active {
  background-color: #fff;
  color: #10CA9C;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.audio-box-sec .audio-box-menu .nav-item .nav-link {
  background-color: #3E3E3E;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  padding: 10px;
  text-align: center;
}

.horigental-line {
  border: 1px solid #BEBEBE;
  width: 100%;
  display: block;
}

.audio-music-box {
  height: 100%;
}

.audio-music-box .card {
  background: linear-gradient(to bottom, #00EDBC, #009DB0);
  border-radius: 10px;
  transition: .4s ease-in-out;
  height: 100%;
}

.audio-music-box .card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: .4s ease-in-out;
}

.audio-music-box .card .card-footer {
  background-color: #54D1CE;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}


.audio-music-box .card .card-title {
  padding: 10px;
  font-size: 14px;
}

.audio-music-box .card-body {
  padding: 0px;
}

.card-music-name h6 {
  font-size: 14px;
}

.audio-slide-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group label {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.audio-voice-box .form-group label {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.audio-slide-box .form-group label {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.audio-language-box .form-group .form-select {
  padding-right: 0px;
}

.audio-voice-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-voice-box .form-group .form-select {
  padding-right: 0px;
}

.audio-add-text-box textarea {
  background: #0C0C0C;
  border-color: #707070;

}

.audio-add-text-box input {
  background: #0C0C0C !important;
  border-color: #707070 !important;

}

.audio-add-text-box textarea:focus {
  background: #0C0C0C;
  box-shadow: none;
}

.audio-add-text-box textarea.form-control {
  color: #787878;
}

.tts-box-wrapper {
  background: #303030;
  border-radius: 10px;
  padding: 10px;
}

.audio-preview-btn button {
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}


.audio-preview-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}

.audio-library-boxs .audio-upload-btn button {
  border-radius: 30px;
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  height: 45px;
  color: #fff;
}

.audio-upload-btn {
  position: relative;
}






.audio-library-boxs .audio-upload-btn .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  border-radius: 30px;
  font-size: 1px;
  height: 45px;
  cursor: pointer;
}

.audio-library-boxs .audio-upload-btn .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  top: 0;
}


.upload-box-card .card {
  background: linear-gradient(to bottom, #B94EFF, #DB438F);
  border: none;
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.upload-box-card .card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: .4s ease-in-out;
}

.upload-box-card .card-footer {
  background: #B240E6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.upload-box-card .card-title {
  padding: 10px;
  font-size: 14px;
}

.upload-box-card .card-body {
  padding: 0px;
}

.card-play-icon {
  cursor: pointer;
}


.remove-logo-switch-btn .form-check-input:checked {
  background: #C23636;
  box-shadow: none;
  border-color: #C23636;
}

.remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}



.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked {
  background: #00BF85;
  box-shadow: none;
  border-color: #00BF85;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}


header .editormenu {
  left: -182px !important;
}

.slide-box {
  overflow-x: auto;
  overflow-y: hidden;
}

.text-font-size {
  width: 120px;
}

.text-box-sec .text-box-wrapper select {
  background-color: #0C0C0C;
  color: #fff;
}

.text-box-sec .text-box-wrapper .text-font-style select {
  border-color: #00F5A0;
}


.font-style-boxs .btn-group button {
  background-color: #0C0C0C;
  margin: 0px 10px;
  border-radius: 5px !important;
}



.profile-sec {
  background-color: #171717;
  padding: 120px 0px 60px;
  min-height: 100vh;
  padding-top: 90px;
}

.user-img {
  position: relative;
  width: 100px;
  height: 100px;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow: hidden;
}

.user-img .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 65px);
  margin-bottom: 0;
  top: 0;
  left: 0;
  background: red;
  font-size: 1px;
  border-radius: 50%;
  opacity: 0;
}


.user-img .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  background: orange;
  border-radius: 100%;
  cursor: pointer;
}

.user-details h5 {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #fff;
}

.user-details button {
  padding: 10px 30px;
  border: none;
  background: linear-gradient(to right, #16B891, #00D3EB);
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.user-details .user-img span {
  color: #14BB9B;
}


.form-box .form-group input {
  background-color: transparent !important;
  border: none;
  padding: 0px;
}

.form-box .form-group input:focus {
  box-shadow: none !important;
}

.form-box .form-group {
  background-color: #1E1E1E;
  color: #fff;
  padding: 5px 20px;
  border-radius: 10px;
  border: 1px solid #707070;
}

.formactive {
  border: 1px solid #00F8B8 !important;
}

.form-box .form-group .form-control {
  color: #fff !important;
  font-weight: 200;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.form-box .form-group ::-webkit-input-placeholder {
  color: #6c757d !important;
}

.form-box .form-group :-ms-input-placeholder {
  color: #6c757d !important;
}

.form-box .form-group ::placeholder {
  color: #6c757d !important;
}


.personal-details-box h6 {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #fff;
}

.form-box button {
  background: linear-gradient(to right, #36D6FF, #C867FF);
  border-radius: 10px;
  border: none;
  height: 45px;
  color: #fff;
}

.form-box button:hover {
  color: #2fcac9 !important
}

.form-box button:focus {
  background: #fff;
  color: #2fcac9;
}

.form-box {
  background-color: #000;
  padding: 50px;
  border-radius: 30px;
}


.gdpr-sec {
  padding: 80px 0px 80px;
  min-height: 100vh;
  background: #171717;
}

.gdpr-update-email {
  padding: 30px;
  background: #000000;
  border-radius: 10px;
}

.gdpr-sec .gdpr-update-email .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.gdpr-update-email .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}




.gdpr-update-email .form-group input:focus {
  box-shadow: none !important;
}

.gdpr-update-email .form-group .form-control {
  color: #fff;
  font-weight: 200;
  font-size: 14px;
}

.gdpr-update-email .form-group ::-webkit-input-placeholder {
  color: #6c757d;
}

.gdpr-update-email .form-group :-ms-input-placeholder {
  color: #6c757d;
}

.gdpr-update-email .form-group ::placeholder {
  color: #6c757d;
}

.gdpr-update-btn button {
  background: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  color: #fff;
  border-radius: 30px;
  padding: 7px 30px;
}

.gdpr-update-btn button:hover {
  color: #2fcac9;
}

.gdpr-update-btn button:focus {
  background: #fff;
  color: #2fcac9;
}


.manage-data-box {
  background: #000000;
  padding: 40px 20px;
  border-radius: 10px;
}

.manage-data-box p {
  font-size: 'Poppins', sans-serif;
  font-size: var(--poppins);
}

.manage-data-box p span {
  color: #f27277;
}

.manage-update-btn button {
  border: none;
  padding: 7px 20px;
  border-radius: 30px;
  color: #fff;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.manage-update-btn button::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 30px;
  z-index: -1;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover {
  color: #2fcac9;
  position: relative;
  z-index: 999;
  overflow: hidden;
}



.add-account-sec {
  padding: 60px 0px;
  background: #171717;
  min-height: 100vh;
  padding-top: 90px;
}
.add-account-sec .infoTitle{
  color: #fff !important;
}

.add-account-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

.add-account-box .form-group input {
  background: transparent;
  border: none;
  padding: 0px;
}

.text-muted {
  color: #ffffffbd !important;
}


.add-account-box .form-group input:focus {
  box-shadow: none;
}

.add-account-box .form-group ::-webkit-input-placeholder {
  color: #6c757d;
  font-weight: 200;
}

.add-account-box .form-group :-ms-input-placeholder {
  color: #6c757d;
  font-weight: 200;
}

.add-account-box .form-group ::placeholder {
  color: #6c757d;
  font-weight: 200;
}

.add-account-box .form-group .form-control {
  color: #fff;
  font-weight: 200;
}

.add-account-box .form-group select {
  background: transparent;
  border: none !important;
  padding: 0px;
  text-align: left;
  height: 24px !important;
  color: #6c757d;
}

.add-account-box .form-group select:focus {
  box-shadow: none;
}

.add-account-box .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}

.add-account-box .form-group:focus-within {
  border-color: #01D084 !important;
}

.add-account-save-btn button {
  background: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  padding: 7px 30px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  border-radius: 30px;
}


.add-account-save-btn button:hover {
  color: #2fcac9;
}

.add-account-save-btn button:focus {
  background: #fff;
  color: #2fcac9;
}

.team-member-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

.text-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  /*white-space: nowrap;*/
  /* width: 150px; */

}




.help-support-banner {
  background-color: #000000;
  margin-top: 65px;
  overflow: hidden;
}


.customer-sec {
  padding: 60px 0px;
  background-color: #242424;
}

.customer-content h6 {
  color: #00FFBA;
}

.customer-content h5 {
  font-size: 28px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.customer-content a {
  padding: 10px 30px;
  background-color: #01D084;
  color: #fff;
  border-radius: 30px;
  margin-top: 10px;
  display: inline-block;
  font-weight: 600;
}

.customer-content a:hover {
  color: #01D084;
  font-weight: 600;
}

.gdpr-update-email .form-group:focus-within {
  border-color: #01D084 !important;
}

.form-group:focus-within {
  border-color: #01D084 !important;
}

.secarchicone-part #searchimg {
  position: relative;
  left: 40%;
}



.help-banner-content .input-group .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-left: 0px;
  background-color: #424a4bcf;
  border: none;
  color: #7A7A7A;
  font-size: 13px;
  padding-right: 0px;
  cursor: pointer;
}

.help-banner-content .input-group .input-group-prepend {
  margin-right: 1px;
}

.help-banner-content .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin: 0px;
  background-color: #424a4bcf;
  border: none;
}

.help-banner-content .input-group input:focus {
  box-shadow: none;
}

.help-banner-content .input-group ::-webkit-input-placeholder {
  color: #7A7A7A;
}

.help-banner-content .input-group :-ms-input-placeholder {
  color: #7A7A7A;
}

.help-banner-content .input-group ::placeholder {
  color: #7A7A7A;
}

.help-banner-content .input-group .form-control {
  color: #fff;
}


.help-banner-content h3 {
  font-size: 42px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 600;
}

.help-banner-content .input-group.mt-4 {
  height: 50px;
}

.help-banner-content h3 span {
  color: #00F5A0;
}

.important-topics span {
  width: 100px;
  height: 100px;
  background-color: #00B5DF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 2px solid #fff;
}


.important-topics span img {
  max-width: 50%;
}

.important-sec {
  background-color: #242424;
  padding: 60px 0px;
}


.important-topics {
  background: linear-gradient(to bottom, #00E2A4, #00B5DF);
  padding: 40px;
  border-radius: 15px;
  transition: .4s ease-in-out;
  height: 247px;
}

.important-topics:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: .4s ease-in-out;
}

.popular-see {
  background-color: #000000;
  padding: 60px 0px;
}

.popular-video-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.important-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.player-box {
  background: linear-gradient(to bottom, #00FDC3, #2DA8C7);
  padding: 10px;
  border-radius: 10px;
}

.support-sec {
  padding: 60px 0px;
  background-color: #242424;
}

.support-content h6 {
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #00F5A0;
}

.support-content h3 {
  font-size: 40px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.support-content p {
  font-size: 18px;
  width: 90%;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.support-content button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  color: #fff;
  padding: 7px 30px;
  border-radius: 30px;
  border: none;
}

.upgrade-menu .nav .nav-link:hover {
  background-color: #00BBAE;
}

.support-content button:hover {
  color: #2fcac9;
}

.support-content button:focus {
  background: #fff;
  color: #2fcac9;
}

.traning-sec {
  background-color: #232323;

  min-height: 100vh;
}

.popular-topics-content {
  background-color: #171717;
  padding: 20px 30px;
  min-height: 100vh;
}

.popular-topics-content .vidoebtn {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  color: #fff;
}

.popular-topics-box .nav {
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px 0px 20px 0px;
}

.popular-topics-box .nav .nav-link {
  color: #fff;
  padding-left: 40px;
}


.popular-topics-box .nav .nav-link.active {
  background-color: #00BBAE;
}



.integration-box .img-wrraper {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-box li {
  max-width: 120px;
  width: 100%;
  background-color: #000000;
  text-align: center;
  padding: 20px;
  margin: 0 0;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 40%), 0 0 20px 0 rgb(0 0 0 / 40%);
  display: block;
  color: #fff;
  cursor: pointer;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: .4s all;
}

.integration-box li:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: .4s all;
}


.integration-box ul li a {
  color: #fff;
}


.integration-sec {
  background: #171717;
  padding: 120px 0px 60px;
}


.my-connection {
  background: #171717;
  padding: 60px 0px;
}

.connection-box {
  background: #000000;
  padding: 20px;
  border-radius: 10px;
  scroll-margin: 80px 0 0 0;
}

.facebook-delete button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.facebook-connection {
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.facebook-connect-account button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.facebook-connect-account button:hover {
  color: #2fcac9;
}

.facebook-connect-account button:focus {
  background: #fff;
  color: #2fcac9;
}

.facebook-delete button:hover {
  color: #2fcac9;
}

.facebook-delete button:focus {
  background: #fff;
  color: #2fcac9;
}

.youtube-connect button:hover {
  color: #2fcac9 !important;
}

.youtube-connect button:focus {
  background: #fff;
  color: #2fcac9;
}

.youbtube-connection .youtube-connect button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.webinar-sec {
  padding: 60px 0px;
  /*background: url('../images/webnibeimg.png') 50%/cover no-repeat;*/


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #00FBC5;
}


.webiner-content p {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #fff;
}

.webiner-content button {
  background: linear-gradient(to right, #00C2FF, #00FFC2);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #000;
  font-weight: 600;
}

.webiner-content button:hover {
  color: #00b889 !important;
}

.warraper {
  min-height: 100vh;
  background: #171717;
}


.upgrads-sec {
  padding: 120px 0px 60px;
  background: #171717;
}


.upgrade-menu {
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
}

.upgrade-menu .nav .nav-link {
  color: #fff;
  padding: 10px 40px;

}

.upgrade-menu .nav .nav-link.active {
  background-color: #00BBAE;

}

.upgrade-content {
  background-color: #000000;
  padding: 20px 30px;
  min-height: 100vh;
}

.upgrade-box-content {
  background-color: #171717;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}

.upgrade-heading h6 {
  background: #00BBAE;
  padding: 10px;
}

.upgrade-para {
  padding: 10px;
}

.upgrade-box-content button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 7px 30px;
  border-radius: 30px;
  border: none;
  color: #fff;
}


.upgrade-para button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.upgrade-para button:hover {
  color: #2fcac9;

}

.upgrade-para button:focus {
  background: #fff !important;
  color: #2fcac9 !important;
}

.upgrade-box-content button:hover {
  color: #2fcac9;
}

.upgrade-box-content button:focus {
  background: #fff !important;
  color: #2fcac9;
}

.tutorial-content .upgrade button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 10px 30px;
  border: none;
  color: #fff;
  border-radius: 30px;

}

.tutorial-content .upgrade button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 257px;
}

.tutorial-content .upgrade button:hover {
  color: #2fcac9;
}

.tutorial-content .upgrade button:focus {
  background: #fff;
  color: #2fcac9;
}


.modal .ecommercebox {
  max-width: 600px !important;
}


.ecommercebox .modal-body .form-group {
  width: 80%;
  margin: auto;
}

.ecommercebox .modal-body .modal-scrollbar {
  display: flex;
  align-items: center;
  justify-content: center;
}


.ecommercebox .modal-body .modal-scrollbar .form-group input {
  background: #1E1E1E;
  height: 50px;
}

.ecommercebox .modal-body .modal-scrollbar .form-group input:focus {
  border-color: #01D084;
  box-shadow: none;
}

.ecommercebox .modal-body .modal-scrollbar .form-group .form-control {
  color: #fff;
}

.ecommercebox .modal-body .modal-scrollbar {
  height: 40vh;
}



.ecommercebox .modal-body .modal-scrollbar .form-group button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: #fff;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:hover {
  color: #2fcac9;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:focus {
  background: #fff;
  color: #2fcac9;
}

.hide-table-padding .form-group {
  background-color: #1E1E1E;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}

.hide-table-padding .form-group:focus-within {
  border-color: #01D084;
}

.hide-table-padding .form-group label {
  font-size: 14px;
  color: #ffffffbd;
}

.brandonfocus:focus-within {
  border-color: #01D084;
  box-shadow: none;
}

.hide-table-padding .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.hide-table-padding .form-group input:focus {
  box-shadow: none;
}

.hide-table-padding .form-group .form-control {
  color: #fff !important;
  font-weight: 200;
}

.hide-table-padding ::-webkit-input-placeholder {
  color: #6c757d;
}

.hide-table-padding :-ms-input-placeholder {
  color: #6c757d;
}

.hide-table-padding ::placeholder {
  color: #6c757d;
}


.template-cover {
  background-image: linear-gradient(to bottom, #00FDC3, #2DA8C7);
  border-radius: 10px;
  padding-top: 1px;
  height: auto;
}

.template-cover .template-img {
  border-radius: 10px;
  position: relative;
  margin: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}

.template-time {
  position: absolute;
  background-color: #fff;
  padding: 1px 10px;
  color: #000;
  top: 5px;
  left: 5px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
}

.template-names {
  padding: 0px 0px 5px;
}

.template-img:hover .ClipsReel-overlay {
  opacity: 1 !important;
  transition: .8s ease-in-out !important;
  -webkit-clip-path: circle(70.7% at 50% 50%) !important;
          clip-path: circle(70.7% at 50% 50%) !important;
}


/** Custom */
.my-account-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #16d489;
}

.my-select {
  border-radius: 5px;
  border: none;
  outline: none
}

.hide-social {
  display: none !important;
}


.sweet-alert {
  color: #0C0C0C !important;
}

.modal {
  z-index: 99999;
}

.show-modal {
  display: block !important;
  background: rgba(0, 0, 0, 0.5);
}

.load-icon-help {
  color: #02c98c;
  font-size: 30px;
}

.webinar-sec {
  padding: 60px 0px;
  background: url(/static/media/webnibeimg.7a9bcc97.png) 50%/cover no-repeat;


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #00FBC5;
}


.webiner-content p {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #fff;
}

.webiner-content button {
  background: linear-gradient(to right, #00C2FF, #00FFC2);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #000;
  font-weight: 600;
}

.webiner-content button:hover {
  color: #00b889 !important;
}

.accnt-mngmt-btn:hover {
  color: #00b889 !important;
}

.upload-btn-wrapper .custom-file-input {
  position: absolute;
  top: 0;
}


.script-sec {
  padding: 120px 0px 60px;
  background-color: #262626;
  min-height: 100vh;
}

.script-sec .form-group label {
  color: #fff;
}

.script-sec .form-group input {
  background-color: #0D0D0D;
  height: 49px;
}

.script-sec .form-group input:focus {
  border-color: #00FFB1;
  box-shadow: none;
}

.script-sec .form-group textarea {
  background-color: #0D0D0D;
  border-color: #707070;

}

.script-sec .form-group textarea:focus {
  border-color: #00FFB1;
  box-shadow: none;
}

.script-sec .form-group .form-control {
  color: #fff;
}

.script-create-btn button,
.custom-next button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a:hover {
  color: #2fcac9;
}


.script-create-btn button:hover {
  color: #2fcac9;
}


.secarchicone-part {
  position: relative;
  -webkit-animation: mymove 3s;
          animation: mymove 3s;
  -webkit-animation-iteration-count: 5;
          animation-iteration-count: 5;
}

@-webkit-keyframes mymove {
  from {
    -webkit-transform: scale(0.09);
            transform: scale(0.09);
  }

  to {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }
}

@keyframes mymove {
  from {
    -webkit-transform: scale(0.09);
            transform: scale(0.09);
  }

  to {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }
}


.fb-card .youtubeicon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-60%, -30%);
          transform: translate(-60%, -30%);
  font-size: 30px;

}


.script-create-btn button:focus-within {
  color: #2fcac9 !important;
  background: #fff !important;
}

.active-dim {
  border: 1px solid #00f5a0;
}

.active-slide {
  border-color: #00f5a0 !important;
}

.modal-backdrop {
  position: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hide-box {
  display: none !important;
}

.story-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.preview-text {
  position: absolute;
  bottom: 0;
  padding: 20px;
}

/*color picker*/
.swatch {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

.swatch-color {
  width: 100%;
  height: 14px;
  border-radius: 2px;
}

.swatch-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.sketch-picker {
  position: absolute;
  z-index: 999;
}

.templateModel {
  margin: auto;
  max-width: 450px;
  height: auto;
}


.templateModel .modal-body {
  height: auto !important;
  box-shadow: 0 0 5px rgba(255, 255, 255, .1);
}

.btn-custom {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.btn-span {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
}

.btn-custom-disable {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.btn-custom:hover {
  color: #2fcac9;
  background: #fff;
}

.preview-text span div {
  display: inline;
}

.highlight-text {
  background-color: yellow;
}


.search-load-box ::-webkit-scrollbar {
  width: 5px;
}

.search-load-box ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.search-load-box ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}

.scroll-bar ::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.scroll-bar ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}

/* Infinite scroll */
.infinite-scroll-component {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.slide-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #10ca9c;
}

.slide-active i {
  color: #10CA9C;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.loader-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.delete-media {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  -webkit-filter: drop-shadow(2px 4px 6px black);
          filter: drop-shadow(2px 4px 6px black);
  width: unset;
  height: unset !important;
}

.delete-media svg {
  position: absolute;
  right: 5px;
  top: 5px;
  fill: #00f5a0;
}

.delete-media i {
  color: #ffffff;
}

.disable-click {
  pointer-events: none
}

.custom-anchor {
  text-decoration: underline !important;
  cursor: pointer;
}

.customModal .modal-content {
  height: 90vh;
}

.reactEasyCrop_Image {
  /*width: 500px;*/
  /*height: 350px;*/
}

.crop-icon {
  position: absolute;
  top: 3px;
  left: 6px;
  width: auto !important;
  height: auto !important;
  z-index: 1;
}

.template-border {
  border: 2px;
  border-color: transparent;
}

.active-template {
  border: 2px solid #ffffff;
}

.rvt-main-container {
  width: 60%;
  margin: auto;
}

.reactEasyCrop_Container {
  width: 60%;
  margin: auto;
}

button:disabled {
  /*background: linear-gradient( to right , #688a81 , #3B91CB);*/
  cursor: auto !important;
  opacity: .3;
}

button:disabled :hover {
  background: linear-gradient(to right, #688a81, #3B91CB) !important;
  cursor: auto !important;
  opacity: .6;
}

.active-audio {
  border: 2px solid #00f5a0;
}

.preview-1-1 {
  width: 40%;
}

.preview-9-16-1 {
  /* width: 50% !important; */
  order: 1;
}

.preview-9-16-2 {
  /* width: 100% !important; */
  order: 2;
  /* margin-top: 10px; */
}

.preview-9-16-3 {
  /* width: 50% !important; */
  order: 3;
}

.icon-response {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.icon-response-span {
  width: 80px;
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  text-align: center;
}

.form-body .gdpr-update-email {
  background: transparent !important;
}

.form-body input,
.form-body textarea,
.form-body select {
  background: #1E1E1E;
  border-color: transparent;
  padding: 0px !important;
}

.form-body input:focus,
.form-body textarea:focus,
.form-body select:focus {
  background: #1E1E1E;
  border-color: transparent;
  padding: 0px !important;
}

/*loader css*/
.loader::after {
  width: 10px;
  height: 10px;
  background-color: #10CA9C;
  border-radius: 100%;
  position: absolute;
  content: "";
}

.loader {
  width: 80px;
  height: 80px;
  border: 2px #10CA9C solid;
  border-radius: 50%;
  -webkit-animation: rotation 1s ease-in-out infinite;
          animation: rotation 1s ease-in-out infinite;
  text-align: center;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loader-sec {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}


.icon-response-span:hover .wrap-icon-image {
  width: 80px;
  height: 80px;
  /* background: red; */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 0px 5px;
  transition: .4s ease-in-out;
}


.icon-response-span .wrap-icon-image {
  width: 80px;
  height: 80px;
  /* background: red; */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: .4s ease-in-out;
}

.active-push {
  border-radius: 50%;
  box-shadow: 0 0 5px #00FDC3;
  transition: .4s ease-in-out;
}

.dimmer-bg {
  background: rgba(0, 0, 0, 0.5);
}

.dimmer-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 1;
}

.aspect-ratio-wrapper:hover .dimmer-layer {
  z-index: 0;
}

.loader-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
}

.logo-style {
  z-index: 2;
  max-width: 100px;
}

.logo-top-left {
  position: absolute !important;
  top: 0;
  left: 0;
  padding: 10px;
}

.logo-top-right {
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 10px;
}

.logo-bottom-right {
  position: absolute !important;
  bottom: 0;
  right: 0;
  padding: 10px;
}

.logo-bottom-left {
  position: absolute !important;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.logo-top-center {
  position: absolute !important;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 10px;
}

.logo-center-left {
  position: absolute !important;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 10px;
}

.logo-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 10px;
}

.logo-center-right {
  position: absolute !important;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 10px;
}

.logo-bottom-center {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 10px;
}

input:read-only {
  cursor: auto;
}

.render-overlay {
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #fff;
  background: rgba(0, 0, 0, .8);
}

/* progress */
.randring-card {
  background-color: #fff;
  padding: 40px 20px;
  height: 192px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.progress-content span {
  color: gray;
  font-size: 12px;
}

.progress-content h5 {
  color: blue;
  font-size: 12px;
}

.progress-status span {
  color: blue;
  font-size: 16px;
  font-weight: 600;
}

.progress-status h5 {
  color: gray;
  font-size: 12px;
}


.bashboard-banner #bg-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}

#bg-video iframe {
  position: absolute;
  min-height: 100vh;
  min-width: 177.77vh;
  width: 100vw;
  height: 56.25vw;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.text-wrappers span {
  display: inline-block !important;
}

.bg-trans {
  background: transparent !important;
}

.text-white a {
  text-decoration: none;
  color: #ffffff;
}

.watermark-op {
  opacity: .5;
}

.center-logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo {
  top: 0;
  object-fit: contain !important;
  /*width: auto !important;*/
}

.traning-sec h6 a[href]:hover {
  color: #00BBAE;
}


/*************    Reel       **/
.reel-select select {
  color: #A5A5A5 !important;
  background: #000000;
  border: none;
}

.reel-select select:focus {
  background: #000000;
  color: #A5A5A5 !important;
  box-shadow: none;
}

.wrap-merge {
  margin-top: 65px;
}

.wrap-merge ul li {
  text-align: center;
  flex-basis: 48%;
  margin: 2px;
  background-color: #000;
  height: auto;
  display: inline-block
}

.merge-wrap-img img {
  height: 100%;
}

.crop-icon-center {
  position: absolute;
  top: 50%;
  left: 50%;
}

.crop-icon-center i {
  font-size: 30px;
}

.custom-form {
  height: auto;
  padding: 8px;
}

.custom-form:focus {
  outline: none;
}

.d-flex select:focus {
  outline: none;
}

#v-pills-client p span {
  color: #ffffff !important;
}

.my-img-wrapper {
  width: 100%;
  height: 100%;
}

.my-img-wrapper img {
  width: 100%;
  height: 100%;
}

/* body{ padding-top: 60px;} */
.help-support-banner {
  margin-top: 50px;
}


.gdpr-sec {
  padding-top: 60px;
}

.footer .footer-menu ul li a:hover {
  color: #00F5A0;
}

.footer-copyright a {
  color: #00F5A0;
}

.footer-copyright a:hover {
  color: #00c6f5 !important;
}

.integration-box li:hover {
  background: linear-gradient(180deg, #00D3EB, #16B891);
  box-shadow: 0 0 20px 0 #00D3EB, 0 0 20px 0 #16B891;
  -webkit-transform: scale(1.1) !important;
          transform: scale(1.1) !important;
}

.user-img .custom-file {
  z-index: 999;
}

.imgEdit {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.user-img:hover .imgEdit {
  opacity: 1;
  visibility: visible;
}

.imgsizeright {
  margin-left: -230px;
}


.commonModal .modal-content {
  background: none !important;
  border: none !important;
}

.commonModal .modal-body {
  background: #000;
  overflow: hidden;
  border-radius: 20px;
  padding: 25px;
}

.commonModal .embed-responsive {
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  position: relative;
  aspect-ratio: 16 / 9;
}

.commonModal .embed-responsive iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.commonModal .modal-dialog {
  max-width: 1130px !important;
}

.commonModal .close {
  position: absolute;
  right: 30px;
  top: -30px;
  opacity: 1 !important;
}

.modal {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.modal.show-modal {
  opacity: 1;
  visibility: visible;
}

.commonModal .modal-dialog {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.5s ease;
}

.commonModal.show-modal .modal-dialog {
  -webkit-transform: scale(1);
          transform: scale(1);
}

/* .logoimg{ margin-top: -60px;} */
.upgrads-sec {
  padding-top: 75px;
  min-height: calc(100vh - 78px);
}

.upgrade-content {
  min-height: 10px;
}

.project-banner {
  padding-top: 92px;
}


.auth-footer {
  padding: 14px 0;
}
#scrollableVideo .infinite-scroll-component,
#scrollableMeme .infinite-scroll-component{
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.note-video-clip{
  width: 100% !important;
}

#docs-internal-guid-3c5ff884-7fff-eaf7-28df-8b269d3273f3{
  display: none !important;
}

/* .reelmerge-editor-part{
  margin-top: 127px;
  padding-bottom: 0 !important;
  height: 708px !important;
} */

.project-create-btn{
  min-width: 180px;
}

@media screen and (max-height: 800px) {
  .commonModal .modal-dialog {
    max-width: 940px !important;
  }
}


@media (max-width: 1699px) {
  .imgsizeleft {
    margin-left: 0;
  }

  .imgsizeleft img {
    max-width: 100%;
  }

  .imgsizeright {
    margin-left: 0;
  }

  .imgsizeright img {
    max-width: 100%;
  }

  .banner-content h1 {
    font-size: 60px;
  }

  .banner-content h4 {
    font-size: 30px;
  }
}

@media (max-width: 1199px) {
  .banner-content h1 {
    font-size: 45px;
  }

  .banner-content h4 {
    font-size: 25px;
  }

  .banner-content p {
    font-size: 15px;
  }

  .banner-video-project .video-project h5 {
    font-size: 35px;
  }

  .dashborad-sec-3-content h4 {
    font-size: 25px;
  }

  .dashborad-sec-3-content h6 {
    font-size: 16px;
  }

  .dashborad-sec-3-content p {
    font-size: 15px;
  }

  .webiner-content p {
    font-size: 15px;
  }

  .webiner-content h3 {
    font-size: 25px;
  }

  .footer .footer-copyright p {
    font-size: 13px;
  }

  .footer .footer-menu ul li a {
    font-size: 13px;
  }

  .integration-sec {
    padding-top: 30px;
    margin-top: 70px;
  }

  .help-banner-content h3 {
    font-size: 25px;
  }

  .secarchicone-part #searchimg {
    left: 0;
  }

  .popular-topics-box .nav .nav-link {
    padding-left: 20px;
  }
}

@media (max-width: 991px) {
  .footer-copyright {
    padding: 8px 0;
  }

  .navbar-collapse {
    position: fixed;
    left: 0;
    top: 71px;
    width: 100%;
    height: 100%;
    background: #000;
    padding: 30px;
    padding-top: 0;
    z-index: 1000;
  }

  .collapse.show {
    z-index: 1000 !important;
  }

  header .navbar ul li {
    margin-right: 0;
  }

  header .navbar .navbar-nav .nav-link {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  header .dropdown button {
    padding: 15px 0;
  }

  header .dropdown-menu {
    position: static !important;
    width: 100%;
  }

  .traning-sec .col-md-4,
  .traning-sec .col-md-8 {
    width: 100%;
  }

  .popular-topics-content {
    min-height: 10px;
    margin-top: 30px;
  }
  /* .reelmerge-editor-part{
    margin-top: 30px;
    height: 400px !important;
  }
  .reelmerge-editor-container{
    padding-bottom: 100px;
  } */
   .save-render-sec{
    margin:0 auto;
   }
   .navbar{
    position: fixed;
    width: 100%;
    z-index: 9;
    top: 0;
    left: 0;
   }
   .editor-sec{
    margin-top: 70px !important;
   }
}

@media (max-width: 767px) {
  .my-table-dark {
    min-width: 570px;
    overflow-x: auto;
    margin-right: 10px;
  }

  .customModal .modal-content {
    height: 100vh;
  }

  .customModal .modal-scrollbar {
    height: 80vh;
  }

  .modal-dialog {
    margin: 0;
  }

  .template-body {
    padding: 15px 0;
  }

  .banner-content h1 {
    font-size: 35px;
  }

  .banner-content h4 {
    font-size: 20px;
  }

  .banner-items {
    padding: 0 0 30px 0 !important;
  }

  .banner-content {
    padding: 20px 0 !important;
    text-align: center !important;
  }

  .help-support-banner {
    padding-top: 60px;
  }

  header .navbar {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .project-button {
    width: 100% !important;
  }

  .project-input-box {
    width: 100% !important;
  }

  .add-campaign.mr-4 {
    margin-right: 0 !important;
    width: 100% !important;
  }

  .add-campaign select {
    width: 100% !important;
    margin-bottom: 15px;
  }

  .add-account-box,
  .team-member-box,
  .gdpr-update-email {
    padding: 15px !important;
  }

  .upgrads-sec {
    padding-top: 0 !important;
    margin-top: 70px;
  }

  .login-content h1 {
    font-size: 40px !important;
  }

  .upgrade-content {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .tutorial-content {
    text-align: center !important;
    padding-bottom: 30px !important;
  }

  .tutorial-content .col-md-10.d-flex.align-items-center {
    justify-content: center !important;
    padding-top: 15px !important;
  }
  .banner-video-project{
    padding-bottom: 0px;
  }
  .dashborad-sec-3{
    padding-top: 0px;
  }
}

.active-help {
  color: #00BBAE !important;
}

.logo-img {
  max-width: 160px;
  min-width: 140px;
  overflow: hidden;
}

@media (max-width:567px) {
  .customModal .modal-scrollbar {
    height: 76vh !important;
  }
  .note-video-clip{
    height: auto !important;
  }
}

@media (max-width: 380px) {
  .facebook-connection {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .facebook-delete button {
    margin-bottom: 10px;
  }
}
:root {
    /* Color Variables */
    --primary: #3498db;
    --secondary: #2ecc71;
    --background-color: #f5f5f5;
    --text-color: #333333;
    --solid-white: #FFFFFF;
    --border-white: #e5e5e5;
    --input-color: #FFFFFF29;
    --input-bg: #F5F5F5;
    --input-br: #b8b8b8;
    --table-bg: #e1e1e1cc;
    --table-text: #1A192E;
    --font-family: "Poppins", serif;

}

* {
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
label,
input,
button,
span,
li,
th,
td,
select,
div {
    font-family: "Poppins", serif;
    font-family: var(--font-family);
}


.whiteLabel_wrapper {
    padding: 45px 10px;
    /* new */
    padding-top: 100px;
    min-height: 95vh;
    /* new */
}

.white-block {
    background: #FFFFFF;
    background: var(--solid-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #e5e5e5;
    padding: 16px;
    margin-top: 25px;
    border-radius: 12px;
}

.White_label_wrap {
    padding-inline: 60px;
}

.white_label_profile {
    display: flex;
    justify-content: space-between;
}

.white-outer {
    background: #d9d9d9;
    color: #FFFFFF29;
    color: var(--input-color);
    padding: 2px;
    border-radius: 8px;
    position: relative;
}

.white_inp {
    width: 100%;
    background: #F5F5F5;
    background: var(--input-bg);
    border-radius: 7px;
    border: none;
    color: #333333;
    color: var(--text-color);
    font-size: 15px;
    font-weight: 400;
    padding: 10px 16px;
}

.white-input-wrap {
    margin-top: 20px;
}

.white-input-wrap label {
    font-size: 15px;
    padding-bottom: 12px;
    display: block;
    font-weight: 600;
}

.white-file-upload {
    height: 200px;
    border: 1px dashed #b8b8b8;
    border: 1px dashed var(--input-br);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.white-file-upload input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.white-swatch-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.white-sketch-picker .sketch-picker {
    position: absolute;
    z-index: 999;
}

.white-theme-btn {
    display: inline-block;
    border: none;
    cursor: pointer;
    background: linear-gradient(90deg, #3498db 0.08%, #2ecc71 101.7%);
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    border-radius: 50px;
    font-size: 15px;
    font-weight: 600;
    color: #FFFFFF;
    color: var(--solid-white);
    padding: 8px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.white-theme-btn:hover {
    opacity: 0.7;
}

.white-list-item {
    background: #f7f7f7;
    padding: 20px;
    border-radius: 15px;
    list-style: none;
}

.white-list-item li {
    position: relative;
    margin-top: 10px;
    padding: 0 0 0 18px;
}

.white-list-item li::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #000;
    position: absolute;
    left: 0;
    top: 6px;
}

.white-item {
    margin-bottom: 10px;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.spin {
    -webkit-animation: spin 1000ms linear infinite;
            animation: spin 1000ms linear infinite;
}

.nav-pills .nav-link.white-link {
    background: #cdcdcd;
    color: #FFFFFF;
    color: var(--solid-white);
    border-radius: 50px;
    padding-left: 15px;
}

.nav-pills .nav-link.white-link.active {
    background: linear-gradient(90deg, #3498db 0.08%, #2ecc71 101.7%);
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    color: #FFFFFF;
    color: var(--solid-white);
    border-radius: 50px;
}

.white_Customization_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.white-titleBar-left h2 {
    font-size: 22px;
    font-weight: 500;
}

.white-titleBar-left p {
    opacity: 0.7;
}

.white-table tr th {
    background: #e1e1e1cc;
    background: var(--table-bg);
}

.white-table tr th,
.white-table tr td {
    padding: 8px 10px;
}

.white-table tr td {
    opacity: 0.7;
    font-weight: 400;
}

.white-table-icon {
    cursor: pointer;
    margin: 0 3px !important;
    padding: 0 !important;
    font-size: 20px;
}

.white_label_modal .modal-dialog {
    max-width: 700px;
}

.white_Label_modal_wrapper {
    padding: 10px;
    color: #000;
}

.white_label_modal .modal-content {
    background: #FFFFFF29;
    background: var(--input-color);
    border-radius: 15px;
    background: linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(90deg, #3498db 0.08%, #2ecc71 101.7%) border-box;
    background: linear-gradient(var(--solid-white), var(--solid-white)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
    border: 1px solid transparent;
}

.white_modalClose {
    font-size: 22px;
    cursor: pointer;
}


.white_label_head h2 {
    font-size: 25px;
    padding-bottom: 10px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
}

.white_label_side_bg {
    background: #e1e1e1cc;
    background: var(--table-bg);
    padding: 20px 22px;
    border-radius: 15px;
}

.white_modalIcon {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #e1e1e1cc;
    background: var(--table-bg);
    font-size: 32px;
    margin: 0 auto;
}

.white_label_integration {
    display: flex;
    justify-content: space-between;
}

.white_label_left_integration {
    justify-content: flex-start;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    display: flex;
}

.white_label_integration_text {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
}

.white_label_integration_text h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}

.white_label_integration_text p {
    font-size: 13px;
    opacity: 0.7;
    padding: 0;
    margin-bottom: 0;
}

.white-table-responsive {
    scrollbar-width: thin;
    scrollbar-color: #2ecc71 #3498db;
    scrollbar-color: var(--secondary) var(--primary);
}

.fade.modal-backdrop.show {
    display: none !important;
}

.fade.white_label_modal.modal.show {
    visibility: visible;
    background: rgba(0, 0, 0, 0.4);
}

.white_modalClose {
    color: #000;
}

@media (max-width: 1200px) {
    .White_label_wrap {
        padding-inline: 0;
    }
}

@media (max-width: 576px) {
    .white_Customization_btn {
        flex-direction: column;
        align-items: self-start;
    }

    .white-titleBar-left h2 {
        font-size: 20px;
    }
}

/* editor css start */
.editor-sec .editor-container .colmn-2 {
    flex: 0 0 100%;
    max-width: 75%;
}

.colmn-preview-wrapper {
    /* height: 100vh; */
    height: calc(100vh - 110px);
}

.editor-sec .editor-container .colmn-1 {
    flex: 0 0 100%;
    max-width: 25%;
}


.editor-sec {
    background: #000000;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding-bottom: 110px;
    min-height: 500px;
}

.save-render-sec {
    background-color: #0c0c0c9e;
    padding: 10px;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin-left: auto;
}

.save-render-sec button {
    font-size: 14px;
}

/* .render-projectbtn {
    margin-right: 85px;
} */
.editor-part {
    overflow-y: auto;
    overflow-x: hidden;
    background: #191919;
    /* padding: 10px 0px; */
    height: calc(100vh - 115px);
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.editor-part::-webkit-scrollbar {
    width: 5px;
    height: 10em;
}

.editor-part::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px grey;
    border-radius: 10px;
}

.editor-part::-webkit-scrollbar-thumb {
    background: #00F5A0 !important;
    border-radius: 10px;
    height: 10px;
}

.save-render-sec .add-campaign input {
    border-radius: 30px;
    border: none;
    background: #161616;
    width: 290px;
    height: 42px;
    font-size: 14px;
}

.save-render-sec ::-webkit-input-placeholder{
    color:#A5A5A5;
}

.save-render-sec :-ms-input-placeholder{
    color:#A5A5A5;
}

.save-render-sec ::placeholder{
    color:#A5A5A5;
}

.save-render-sec .add-campaign .form-control{
    color:#fff;
}

.save-render-sec .save-projectbtn button{
    /* background: linear-gradient( to right , #00FFBA , #3B91CB); */
    background: #ffffff;
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 50%,#00ffba 50%,#3b91cb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#3b91cb',GradientType=1 );
    background-size: 202%;
    background-position: 99% 50%;
    transition: .3s ease-in-out;
    padding: 7px 15px;
    border-radius: 5px;
    border:none;
    color:#fff;
}

.save-render-sec .render-projectbtn button{
    /* background: linear-gradient(to right , #A72CE3 , #CB3B71); */
    background: #ffffff;
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 50%,#a72ce3 50%,#cb3b71 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#cb3b71',GradientType=1 );
    background-size: 202%;
    background-position: 99% 50%;
    transition: .3s ease-in-out;
    padding: 7px 25px;
    border-radius: 5px;
    border:none;
    color:#fff;
}

.editor-tab-menu .nav-item .nav-link h6 {
    color: #fff;
    margin-top: 5px;
    font-size: 12px;
    font-family: var(--poppins);
    margin-bottom: 0px;
}

.editor-tab-menu .nav-item {
    padding: 0px 10px;
}

.editor-tab-menu .nav-item .nav-link.active {
    background: #414141;
    border-radius: 10px;
    padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link.active h6{
    color:#00FFB1;
}

.editor-tab-menu .nav-item .nav-link.active svg path {
    fill: #00FFB1;
}

.editor-tab-menu .nav-item .nav-link{
    padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link .img-wrapper {
    width: 24px;
    height: 28px;
}

.editor-tab-menu{
    padding: 10px;
}

.editor-preview{
    padding: 3vh 2vw;
    padding-bottom: 200px;
    background: #333333;
    position: relative;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.editor-preview::-webkit-scrollbar {
    width: 5px;
}
.editor-preview::-webkit-scrollbar-thumb {
    background: #00f5a0;
    border-radius: 10px;
}
.editor-preview::-webkit-scrollbar-track {
    background: #0000;
}

.aspect-ratio-wrapper {
    position: absolute;
    /* background: #fff; */
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* border-radius: 10px; */
    margin: auto;
    /* top: 0; */
    bottom:0;
    left: 0;
    right: 0;
    /* box-shadow: 0px 0px 15px #00000087; */
}

.story-part-sec .story-para p{
    font-size: 12px;
    font-family: var(--poppins);
    color:#fff;
}

.story-part-sec .story-para {
    background-color: #242424;
    padding: 20px;
    border-radius: 30px;
    border: 1px solid #707070;
    cursor: pointer;
}

.editor-preview-box {
    width: 100%;
    height: auto;
    /* overflow: hidden; */
    margin: auto;
}

.ep-as-r-9-16 .editor-preview-box {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-transform-origin: 50% 105%;
            transform-origin: 50% 105%;
}

.aspect-ratio-wrapper img,
.preview-def-wh,
.aspect-ratio-wrapper video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.para-active{
    background-color: #2DA882 !important;
}

.para-unactive {
    background: none;
}

.slide-box ul li {
    flex: 0 0 auto;
    /* flex-basis: auto; */
    width: auto;
    height: 70px;
    margin: 0px 10px;
    overflow: hidden;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    border: 2px solid #707070;
    transition: .3s ease-in-out;
    position: relative;
}

.slide-box ul li .box-ar-wrapper img,
.slide-box ul li .box-ar-wrapper video {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
}

.slide-box ul li .box-ar-wrapper {
    
    background-color: #101010;
    border-radius: 10px;
    overflow: hidden;
}

.slide-box ul li:hover{
    border-color: #00F5A0;
    transition: .3s ease-in-out;
}


.slide-box {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
    display: flex;
    align-items: center;
}

.slide-box::-webkit-scrollbar-thumb {
    background: #00f5a0;
    border-radius: 10px;
}

.slide-box::-webkit-scrollbar-track {
    background: #0000;
}

.slide-box::-webkit-scrollbar {
    height: 4px;
}

.slide-sec ul {
    /* width: 1350px; */
    width: -webkit-max-content;
    width: max-content;
    /* margin: 0 auto!important;
    justify-content: center; */
}

.slide-sec{
    background-color: #000000;
    padding: 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
}

.slide-box .plus-icon i{
    color:#565656;
}

/* Control Box Start */

.control-time {
    width: 100%;
    background: transparent;
    border: none;
    color:#fff !important;
    /* font-size: 0.7vw; */
    font-size: 10px;
    /* margin-right: 10px; */
}

.control-time:focus-visible{
    outline: none !important;
}

.control-time::-webkit-input-placeholder{
    color:#fff;
}

.control-time:-ms-input-placeholder{
    color:#fff;
}

.control-time::placeholder{
    color:#fff;
}

.ct-end {
    text-align: right;
}

.controls-box {
    background: #000;
    padding: 2vh 0.5vw;
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 3px 5px -1px #000;
    /* height: 70px; */
    display: flex;
    align-items: center;
    justify-content: center;
    top: -4px;
}

.controls-box .cb-col-3 .start-end-time {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
}

.controls-box .cb-col-3 input {
    text-align: right;
}

.play-push-left-right ul img {
    /* max-width: 1.1vw; */
    max-width: 10px;
}

.control-time:focus{
    box-shadow: none !important;
}

/* Control Box End */

.save-projectbtn button:hover{
    color:#2fcac9;
}

.save-projectbtn button:focus{
    background-color: #fff !important;
    background-image: unset !important;
    color:#2fcac9 !important;
}
.add-campaign input:focus{
    box-shadow: none;
}

.render-projectbtn button:hover{
    color:#2fcac9;
}

.render-projectbtn button:focus{
    background-color: #fff !important;
    background-image: unset !important;
    color:#2fcac9 !important;
}

/* .style-upload-part {
   background: #303030;
   padding: 10px;
   border-radius: 10px;
} */

.style-upload-box {
    background-color: #171717;
    height: 77px;
    border: 1px dashed #707070;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style-upload-box .custom-file-input{
    position: absolute;
    top: 0;
    opacity: 0;
    background: red;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.style-upload-box h6{
    font-size: 12px;
    margin-bottom: 0px;
    color:#8F8F8F;
}

.style-upload-box .custom-file{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.position-text-box {
    /* background: #303030; */
    padding: 0;
    border-radius: 10px;
    height: 100%;
}
.position-text-box .text-position-dropdown label {
    font-size: 14px;
    margin-left: 4px;
}

.style-part-sec {
    padding: 24px 15px 0;
}

.style-box-wrapper .colmn {
    background: #303030;
    height: 165px!important;
    padding: 15px;
    border-radius: 10px;
}

.style-box-wrapper .colmn.col-md-8 {
    max-width: 63%;
}

.style-upload-part .style-remove-logo .form-check-input {
    position: unset;
}

.style-upload-part .style-remove-logo .form-switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.style-remove-logo .remove-logo-switch-btn {
    width: 100%;
}

.position-text-box .text-position-dropdown .box:hover{
    background-color: #00BF85;
    transition: all .2s;
    border:2px solid #00BF85;
}
.position-text-box .text-position-dropdown .box.box-active{
    background-color: #00BF85 !important;
    border:2px solid #00BF85 !important;

}

.position-text-box .text-position-dropdown .box {
    margin-bottom: 9px;
    border: 2px solid #707070;
    border-radius: 0;
    background-color: #636363;
    cursor: pointer;
    padding: 10px;
    color: #fff;
    transition: all .2s;
    flex: 0 0 33.33%;
    max-width: 24%;
}

.position-text-box .text-position-dropdown .box-wrapper {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
}

.remove-logo-switch-btn .form-check label{
    font-size: 12px;
    color:#8F8F8F;
}

.remove-logo-switch-btn .form-check .form-check-input{
    margin-top: 2px;
    margin-left: 10px;
    font-size: 24px;
    background-position: calc(100% - 90%) calc(100% - 0px);
}

.style-watermark .remove-logo-switch-btn .form-check .form-check-input {
    background-position: calc(100% - 90%) calc(100% - 0px);
}

.style-remove-cricle{
    width: 50px;
    height: 50px;
    background-color: #000;
    border-radius: 50%;
    border:1px solid #686868;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.style-remove-cricle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.dimmer-wrapper .dimmer-light h6{
    font-size: 14px;
}

.dimmer-switch-btn .remove-logo-switch-btn  .form-check-input{
    margin-top: 2px;
    font-size: 24px;
}

.swap-template-wrapper,
.dimmer-wrapper {
    padding: 10px;
    border-radius: 10px;
    background: #303030;
}

.swap-template-wrapper .colmn {
    margin-bottom: 20px;
    max-width: 48%;
}

.swap-template-wrapper .swap-template-box{
    background-color: #101010;
    height: auto;
}

.swap-template-wrapper h6{
    font-size: 14px;
}

.editor-tab-menu {
    /* width: 110%; */
    background: #232323;
}

/* editor text customization start */
.editor-main {
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    height: 100vh;
    /* width: 100%; */
}

/* .editor-container .colmn:nth-child(2) .colmn-preview-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
} */

.editor-sec .editor-container .colmn-2::-webkit-scrollbar-thumb {
    background: #00F5A0;
    border-radius: 10px;
}

.editor-sec .editor-container .colmn-2::-webkit-scrollbar-tracks {
    background: #0000;
}
.editor-sec .editor-container .colmn-2::-webkit-scrollbar {
    width: 10px;
}

.editor-preview-wrapper {
    /* box-shadow: 0px 0px 15px #00000087; */
    border-radius: 10px;
    /* overflow: hidden; */
    width: 100%;
    max-width: 70%;
    height: auto;
    margin: 0 auto;
}

/* .editor-sec .editor-container .colmn-1 {
    height: 100%;
} */

/* .editor-preview, */
.editor-part .tab-content,
.editor-sec .editor-container {
    height: 100%;
}

.editor-text-customization {
    padding: 10px;
}

#pills-media, #pills-images, #pills-video, #pills-medialibrary,
#pills-audio {
    overflow: hidden;
    /* height: 88%; */
}

.search-load > div video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.search-load > div {
    height: 100%;
    width: 100%;
}

.search-load-box, .scroll-wrapper, .upload-audio-box {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px 0 0;
}

.search-load-box .row {
    /* max-height: 80vh; */
    height: calc(100vh - 265px);
}

.scroll-wrapper > .row {
    /* height: 75vh; */
    height: calc( 100vh - 295px );
}

.upload-audio-box > .row {
    height: 620px;
}

.editor-part .tab-pane {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 0;
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.editor-part .tab-pane, .search-load-box,
.scroll-wrapper, .upload-audio-box {
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.editor-part .tab-pane::-webkit-scrollbar-thumb,
.search-load-box::-webkit-scrollbar-thumb,
.scroll-wrapper::-webkit-scrollbar-thumb,
.upload-audio-box::-webkit-scrollbar-thumb {
    background: #00f5a0;
    border-radius: 10px;
}

.editor-part .tab-pane::-webkit-scrollbar-track,
.search-load-box::-webkit-scrollbar-track,
.scroll-wrapper::-webkit-scrollbar-track,
.upload-audio-box::-webkit-scrollbar-track {
    background: #0000;
}

.editor-part .tab-pane::-webkit-scrollbar,
.search-load-box::-webkit-scrollbar,
.scroll-wrapper::-webkit-scrollbar,
.upload-audio-box::-webkit-scrollbar {
    width: 5px;
}

.editor-text-customization .editor-text-family {
    position: relative;
    flex: 0 0 70%;
    max-width: 70%;
}

.editor-text-customization .editor-text-weight-size {
    flex: 0 0 29%;
    max-width: 29%;
}

.editor-text-customization select ~ .icon {
    position: absolute;
    top: 50%;
    right: 15px;
    line-height: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.editor-text-customization  .editor-input-dark {
    color: #fff !important;
    padding: 5px ​10px 5px;
    border-radius: 4px !important;
    border: 1px solid #707070;
    background-color: #0C0C0C !important;
    height: auto;
    box-shadow: none;
    resize: none;
    height: 43px!important;
    letter-spacing: 1px;
}

.editor-text-customization select {
    padding: 7px 12px;
    font: 500 14px/normal "Poppins",sans-serif;
}

.editor-text-customization .txt-edit-btn {
    flex: 16.6666% 1;
    max-width: 16%;
}

.editor-text-alignment .txt-edit-btn {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 36px;
    height: 36px;
}

.editor-text-customization select:focus,
.editor-text-customization input:focus,
.editor-text-customization button.active {
    border-color: #00FFB1;
}

.editor-text-customization #t-lineheight {
    padding-left: 32px;
}

.editor-text-customization .input-group-prepend {
    position: relative;
    flex: 0 0 31%;
    max-width: 31%;
}

.editor-text-align .txt-edit-btn {
    flex: 0 0 17.25%;
    max-width: 16.5%;
}

.editor-text-customization .input-group-prepend .icon {
    position: absolute;
    top: 45%;
    left: 10px;
    z-index: 1;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #747474;
}

.editor-font-color #tColor-hex,
.editor-font-color #tBg-hex {
    padding-left: 40px;
}

.editor-font-color .input-color {
    position: relative;
    flex: 0 0 50%;
    max-width: 48.7%;
}

.editor-font-color .editor-rgb-wrapper {
    flex: 0 0 16%;
    max-width: 16%;
}

.editor-font-color #t-color-btn,
.editor-font-color #t-bg-btn {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 8px;
    border-radius: 3px;
    border: 2px solid hsla(0,0%,100%,.5);
    z-index: 1;
    -webkit-transform: translateY(-120%);
            transform: translateY(-120%);
    transition: none !important;
}
.editor-font-color .swatch, .editor-font-color .no-color {
    height: 35px;
    border: 0;
    border-radius: 2px;
    padding: 0;
    flex: 0 0 9%;
    max-width: 8.8%;
    margin: 0px 1px 1px 0;
    overflow: hidden;
    outline: none !important;
    position: relative;
}

.editor-font-color .no-color::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 2px;
    height: 50px;
    background: #a2000f;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin: auto;
}

.editor-wrapper {
    padding: 10px 15px;
}

.editor-font-color {
    background: #303030;
    border-radius: 10px;
}

/* editor text customization End */

/* media tab css start */
.media-menu-box .nav .nav-item {
    flex: 0 0 24.9%;  /* 0 0 33.33% */
    text-align: center;
}

.media-menu-box .nav .nav-item:nth-child(2),
.audio-box-menu .nav .nav-item:nth-child(2) {
    border-left: 1px solid #3e3e3e;
    border-right: 1px solid #3e3e3e;
}

/* ---------------- */
.media-menu-box .nav {
    border-radius: 100px;
    overflow: hidden;
    padding: 0!important;
}

.media-menu-box .nav-item .nav-link{
    color: #737373;
    background-color: #000;
    padding: 12px 0px;
    font-size: 12px;
    font-family: var(--poppins);
}



.media-menu-box .nav-item .nav-link.active{
    background-color: #10CA9C;
    color: #fff;
}

.media-search-box .input-group .input-group-prepend .input-group-text{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #fff;
    border:1px solid #fff;
    color:#979797;
    cursor: pointer;
}
.media-search-box .input-group input{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border:none;
    height: 45px;
}

.media-search-box .input-group-prepend{
    margin-left: 0px;
}

.media-search-box input:focus{
    box-shadow: none;
}

.search-load {
    background-color: #101010;
    height: 100px;
    border: 1px solid #707070;
    overflow: hidden;
}

.search-load img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: top;
    z-index: 1;
}

.search-load-wrapper-blur-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.search-load-wrapper-blur-bg img {
    position: absolute;
    top: 0;
    object-fit: cover;
    /*filter: blur(10px);*/
    width: 100%;
}

.search-load-box .search-load-wrapper-blur-bg {
    overflow: hidden;
}

.search-load-box .search-load-wrapper-blur-bg img{
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}

.search-load-box .colmn {
    margin-bottom: 20px;
    max-width: 48%;
    padding: 0;
}

.search-load .search-img-result {
    z-index: 1;
}

.media-upload-box button{
    /* background:linear-gradient(to right, #10CA9C , #a72ce3); */
    background: #10ca9c;
    background: linear-gradient(to right,  #10ca9c 0%,#a72ce3 50%,#10ca9c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10ca9c', endColorstr='#10ca9c',GradientType=1 );
    background-size: 200% auto;
    transition: .5s ease-in-out all;
    border:none;
    color:#fff;
    border-radius: 5px;
    height: 100%;

}

.hover-effect {
    background-position: right!important;
}

.media-upload-box{
    position: relative;
    height: 45px;
}

.media-upload-box .custom-file{
    position: absolute;
    top:0px;
    height: 100%;
}

.media-upload-box .custom-file .custom-file-input{
    opacity: 0;
    background-color: red;
    border-radius: 30px;
    font-size: 2px;
    position: absolute;
    top:0px;
    cursor: pointer;
    height: 100%;
}

/* media start css */


.credites-wrapper textarea{
    background-color: #0C0C0C;
    border:1px solid #707070;
}

.credites-wrapper textarea:focus{
    background-color: #0C0C0C;
    box-shadow: none;
}

.credites-wrapper textarea.form-control{
    color:#787878;
    font-size: 14px;
}


.credites-wrapper label{
    font-family: var(--poppins);
}

.credites-upload-box .outer-images{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.credites-upload-box .outer-images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.credites-uplaod {
    position: relative;
    height: 100px;
    background-color: #171717;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px dashed #707070
}

.credites-uplaod h6{
    font-size: 14px;
    color:#8F8F8F;
}

.credites-uplaod .custom-file {
    position: absolute;
    top: 0;
}


.credites-uplaod  .custom-file-input {
    position: absolute;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
    background: red;
    height: 261%;
    font-size: 1px;
    cursor: pointer;
}

.credites-upload-box {
    padding: 20px;
    background: #303030;
    border-radius: 10px;
}

.credites-upload-box label {
    font-size: 14px;
    color: #737373;
}

.remove-outer-logo  .form-check-input {
    margin-left: 20px;
    position: unset;
    font-size: 24px;
}

.outro-template-box{
    padding: 20px;
    background: #303030;
    border-radius: 10px;
}

.outro-template-box h6{
    font-family: var(--poppins);
    font-size: 14px;
}

.outer-images-box{
    background-color: #000000;
    height: auto;
}

.outer-template-wrapper .colmn {
    margin-bottom: 20px;
}

/* audio editor css start */
#music-library-nav {
    border-radius: 0;
}

#music-library-nav .nav-item {
    flex: 0 0 50%;
    border-right: none;
    padding: 0px 15px!important;
}

#music-library-nav .nav-item .nav-link {
    background: none;
    border: none;
}

#music-library-nav .nav-item .nav-link.active {
    border-bottom: 2px solid  #10ca9c;
}

#pills-sonority.sonority-wrapper .card {
    background: #6326ac;
    background: linear-gradient(to bottom,  #6326ac 0%,#7677f6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6326ac', endColorstr='#7677f6',GradientType=0 );
    border: 0;
}

#pills-sonority.sonority-wrapper .card .card-footer {
    background: #570ea9;
}

.card-header-uploaded {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
}

.card-icon-delete {
    cursor: pointer;
    top: -3px;
    -webkit-filter: drop-shadow(0px 0px 10px rgba(0 0 0 / 100%));
            filter: drop-shadow(0px 0px 10px rgba(0 0 0 / 100%));
}

.active-audio .active-audio-wrapper i {
    color: #00f5a0;
}

.active-audio .active-audio-wrapper {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000b3;
    z-index: 1;
    border-radius: 7px;
}

.active-audio-wrapper {
    display: none;
}

.audio-box-menu .tab-pane {
    padding: 10px;
}

.audio-box-menu .nav .nav-item {
    flex: 0 0 33.33%;
    text-align: center;
}

.audio-music-box .card-body img,
.upload-box-card .card-body img {
    width: 45%;
}

.audio-box-menu .tab-pane .colmn,
.audio-music-box .card .card-footer .col-md-9 {
    padding: 0 5px;
}

.audio-box-menu .tab-pane .row .colmn {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-bottom: 10px;
}

.audio-box-sec .audio-box-menu .nav-item .nav-link.active {
    background-color: #10CA9C;
    color: #fff;
}

.audio-box-menu .nav {
    padding: 0!important;
    border-radius: 100px;
    overflow: hidden;
}

.audio-box-sec .audio-box-menu .nav-item .nav-link{
    background-color:#000;
    color: #737373;
    font-size: 12px;
    font-family: var(--poppins);
    padding: 12px 0;
}

.horigental-line {
    border: 1px solid #BEBEBE;
    width: 100%;
    display: block;
}

.audio-music-box .card{
    background: linear-gradient(to bottom , #00EDBC , #009DB0);
    border-radius: 10px;
    transition: .4s ease-in-out;
}

.audio-music-box .card.active {
    border: 3px solid #a72ce2;
}

.audio-music-box .card:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: .4s ease-in-out;
}

.audio-music-box .card .card-footer{
    background-color:#54D1CE ;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}


.audio-music-box .card .card-title {
    padding: 10px;
    font-size: 12px;
}

.audio-music-box .card-body{
    padding: 0px;
}

.card-music-name h6 {
    font-size: 9px;
}

.card-play-icon .fa-2x {
    font-size: 1.2em;
}

.tts-box-wrapper .audio-slide-box input {
    height: 40px;
    background: #0C0C0C;
    color: #fff;
    border-color: #626262;
}

.audio-slide-box  .form-group select{
    background-color:#0C0C0C;
    color:#fff;
    box-shadow: none;
    border-color: #707070;
    font-size: 14px;
}

.audio-language-box  .form-group select{
    background-color:#0C0C0C;
    color:#fff;
    box-shadow: none;
    border-color: #707070;
    font-size: 14px;
    padding: 7px 0px 10px 12px;
}
.audio-language-box .form-group label{
    font-size: 12px;
    font-family: var(--poppins);
}
.audio-voice-box .form-group label{
    font-size: 12px;
    font-family: var(--poppins);
}

.audio-slide-box .form-group label{
    font-size: 12px;
    font-family: var(--poppins);
}

.audio-language-box  .form-group .form-select,
.audio-voice-box .form-group .form-select{
    padding-right: 0px;
    height: 40px;
}

.audio-voice-box .form-group select{
    background-color:#0C0C0C;
    color:#fff;
    box-shadow: none;
    border-color: #707070;
    font-size: 14px;
    padding: 7px 0px 10px 12px;
}

.audio-add-text-box textarea{
    background: #0C0C0C;
    border-color: #707070;
    font-size: 14px;
}

.audio-add-text-box textarea:focus{
    background: #0C0C0C;
    box-shadow: none;
}

.audio-add-text-box textarea.form-control{
    color:#787878;
}

.tts-box-wrapper{
    background: #303030;
    border-radius: 10px;
    padding: 10px;
    height: calc(100vh - 230px);
    overflow-x: hidden;
    overflow-y: auto;
}

.tts-box-wrapper label {
    color: #737373;
}

.audio-preview-btn button{
    background: #ffffff;
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 50%,#10ca9c 50%,#a72ce3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#a72ce3',GradientType=1 );
    background-size: 202%;
    background-position: 99% 50%;
    border:none;
    color:#fff;
    border-radius: 5px;
    height: 45px;
    transition: .3s ease-in-out;
}

.btn-change6:hover {
    background-position: 0%!important;
    color:#2fcac9!important;
}

.btn-change6:focus {
    color: #fff;
}


.audio-save-use-btn button{
    background: #ffffff;
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 50%,#00ffba 50%,#3b91cb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#3b91cb',GradientType=1 );
    background-size: 202%;
    background-position: 99% 50%;
    border:none;
    color:#fff;
    border-radius: 5px;
    height: 45px;
    transition: .3s ease-in-out;
}

.audio-save-use-btn button:hover{
    color:#2fcac9;
}



.audio-library-boxs .audio-upload-btn button{
    border-radius: 5px;
    /* background: linear-gradient(to right, #10CA9C , #a72ce3); */
    background: #10ca9c;
    background: linear-gradient(to right,  #10ca9c 0%,#a72ce3 50%,#10ca9c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10ca9c', endColorstr='#10ca9c',GradientType=1 );
    background-size: 200% auto;
    transition: .5s ease-in-out all;
    border:none;
    height: 45px;
    color:#fff;
}

.audio-upload-btn{
    position: relative;
}

.editor-wrapper .editor-text-color textarea.form-control {
    height: 150px!important;
}

.audio-library-boxs  .audio-upload-btn .custom-file-input {
    position: absolute;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
    background: red;
    border-radius: 30px;
    font-size: 1px;
    height: 45px;
    cursor: pointer;
}

.audio-library-boxs .audio-upload-btn .custom-file {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
    top: 0;
    height: 100%;
}


.upload-box-card .card{
    background: linear-gradient(to bottom , #B94EFF , #DB438F);
    border:none;
    border-radius: 10px;
    transition: .4s ease-in-out;
}

.upload-box-card .card:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: .4s ease-in-out;
}

.upload-box-card .card-footer{
    background: #B240E6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.upload-box-card .card-title {
    padding: 10px;
    font-size: 12px;
}

.upload-box-card .card-body{
    padding: 0px;
}

.card-play-icon{
    cursor: pointer;
    display: flex;
    align-items: center;
}


.remove-logo-switch-btn .form-check-input:checked{
    background: #C23636;
    box-shadow: none;
    border-color:#C23636;
}

.remove-logo-switch-btn  .form-switch .form-check-input:checked {
    background-position: calc(100% - 10%) calc(90% - 1px)!important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    box-shadow: none;
}


.remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    box-shadow: none;
    background-position: calc(100% - 10%) calc(90% - 1px)!important;
}



.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked{
    background: #00BF85;
    box-shadow: none;
    border-color:#00BF85;
}


.dimmer-switch-btn  .remove-logo-switch-btn  .form-switch .form-check-input:checked {
    background-position: right center !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    box-shadow: none;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    box-shadow: none;
}


/* Video Tiles Start */
.slider-9-16 .video-tiles-controls,
.slider-1-1 .video-tiles-controls {
    flex-wrap: wrap;
}

.video-tiles-controls {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(to bottom, #000000d4 100%,#fff0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#00ffffff',GradientType=0 );
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
}

.slider-9-16 .video-tiles-controls,
.slider-1-1 .video-tiles-controls {
    height: 21px;
    padding-top: 16px;
}

.video-tiles-controls .refresh-delete {
    position: absolute;
    right: 2px;
    top: 2px;
}

.slider-9-16 .video-tiles-controls .refresh-delete,
.slider-1-1 .video-tiles-controls .refresh-delete {
    position: absolute;
    right: 0;
    top: -2px;
    left: 0;
}

.video-tiles-controls a {
    margin: 0 2px;
}

.video-tiles-controls .cursor-pointer i {
    color: #00f5a0;
    -webkit-filter: drop-shadow(2px 4px 6px black);
            filter: drop-shadow(2px 4px 6px black);
    font-size: 12px;
}

.video-tiles-controls .cursor-pointer i.fa-crop {
    position: absolute;
    top: 9px;
}

.slider-9-16 .cursor-pointer i,
.slider-1-1 .cursor-pointer i {
    font-size: 8px;
}

.video-tiles-time-duration input:focus {
    outline: none;
    border: 1px solid #01eb9a;
    border-radius: 5px;
}

.video-tiles-time-duration input {
    width: 50px;
    /* position: absolute; */
    left: 0px;
    border: 0;
    background: #0000;
    color: #fff;
    top: -1px;
    font-size: 9px;
}

.slider-1-1 .video-tiles-time-duration input {
    width: 30px;
}

.slider-9-16 .video-tiles-time-duration input {
    width: 12px;
}

.slider-9-16 .video-tiles-time-duration input,
.slider-1-1 .video-tiles-time-duration input {
    font-size: 8px;
}

.video-tiles-time-duration input::-webkit-outer-spin-button,
.video-tiles-time-duration input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.video-tiles-time-duration input[type=number] {
  -moz-appearance: textfield;
}

.video-tiles-time-duration {
    display: flex;
    align-items: center;
}

.video-tiles-time-duration span {
    font-size: 9px;
    margin-left: 5px;
    color: #fff;
    letter-spacing: 1px;
}

.slider-9-16 .video-tiles-time-duration span,
.slider-1-1 .video-tiles-time-duration span {
    font-size: 8px;
    margin-left: 1px;
    letter-spacing: 0.5px;
}



/* Template1_2 wrapper preview start */


/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
/* Please! Dont touch this! Dont change the position-absolute into positio relative! it causes the template break!  
*/
.template-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.template-wrapper *{
    position: relative;
  }
/* Please! Dont touch this! Dont change the position-absolute into positio relative! it causes the template break!  */
/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

/* Template1_2 wrapper preview end */

/* Aspect Ratio Start */
.preview-wrapper {
    height: 100%;
    position: relative;
}

.ar-16-9 {
    max-width: 100%;
    max-height: 100%;
}

.ar-9-16 {
    max-width: 171px;
    max-height: 304px;
}

.ar-1-1 {
    max-width: 310px;
    max-height: 310px;
}

.ar-4-3 {
    max-width: 90%;
    max-height: 100%;
}

.ar-3-4 {
    max-width: 50%;
    max-height: 100%;
    margin: 0 auto;
}

.slider-16-9, .slider-16-9 .box-ar-loader {
    width: 150px!important;
    height: 70px!important;
    margin: 0 auto!important;
}

.slider-9-16, .slider-9-16 .box-ar-loader {
    width: 50px!important;
    height: 70px!important;
    margin: 0 auto!important;
}

.slider-1-1, .slider-1-1 .box-ar-loader {
    width: 70px!important;
    height: 70px!important;
    margin: 0 auto!important;
}

.slider-3-4, .slider-3-4 .box-ar-loader {
    width: 60px!important;
    height: 70px!important;
    margin: 0 auto!important;
}

.slider-4-3, .slider-4-3 .box-ar-loader {
    width: 90px!important;
    height: 70px!important;
    margin: 0 auto!important;
}

.upload-audio-primary{
    height: calc(100vh - 300px);
    overflow-x: hidden;
    overflow-y: auto;
}
.alert-height{
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}
@media screen and (max-width: 440px) {

    .editor-tab-menu .nav-item:nth-child(n+4) {
        margin-top: 20px;
    }

    .editor-tab-menu .nav-item {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }

    .style-part-sec .style-box-wrapper .colmn.col-md-8,
    .style-part-sec .style-box-wrapper .colmn:last-child {
        max-width: 100%!important;
    }

    .save-render-sec .save-projectbtn, .save-render-sec .render-projectbtn {
        flex: 0 0 50%;
        max-width: 45%!important;
    }

    .start-end-time input {
        font-size: 10px!important;
        /* width: 50px!important; */
    }

    /* .cb-col.cb-col-1 {
        width: 100%!important;
        padding: 0px 10px!important;
    }

    .cb-col.cb-col-2 {
        width: 70%!important;
    } */

    .play-push-left-right img, .expand-button img {
        width: 12px!important;
    }

}

@media screen and (max-width: 575px) {
    .audio-box-sec .audio-box-menu .tab-pane .row .colmn {
        max-width: 50%!important;
    }

    .banner-content {
        padding: 40px 0px;
    }
    .banner-content h1 {
        font-size: 50px;
    }

    .project-header-name .project-item h4 {
        font-size: 20px;

    }
    .project-header-name .project-conut h6{
        font-size: 20px;
    }

    .banner-content .watch-demo-btn button{
        padding: 15px 50px;
    }
    .banner-video-project .video-project a span {

        margin-left: 0 !important;
    }



    .banner-video-project .video-project a{
        font-size: 14px;
    }

    .banner-video-project .video-project h5 {
        font-size: 20px;

    }



    .banner-content p {
        width: 100%;
    }
    .banner-items{
        padding: 40px 0px;
    }

    .dashborad-sec-3{
        padding: 40px 0px;
    }

    .imgsizeleft{
        margin-top: 30px;
    }

    .dashborad-sec-3-content {
        margin-top: 30px;
    }
    header .dropdown-menu {
        left: 0 !important;
    }

    .video-card{
        margin-top: 30px;
    }

    .login-box{
        padding: 40px 0px;
    }

    .login-form{
        padding: 20px;
        margin-top: 20px;
    }
    .login-form p{
        font-size: 14px;
    }

    .login-form h4 {
        font-size: 17px;
    }
    .login-content h5 {
        font-size: 20px;
    }

    .project-button{
        flex-direction: column !important;
        margin-top: 20px;
        justify-content: center;
    }

    .project-button .project-create-btn{
        margin-top: 20px;
    }

    .project-input-box{
        margin-right: 0px;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }


    .search-select{
        flex-direction: column;
        justify-content: start;
    }

    .search-select .search-box{
        margin-top: 20px;
    }

    .template-name h4{
        font-size: 14px;
    }

    .template-number h6{
        font-size: 14px;
    }

    .select-box {
        margin-top: 15px;
    }

    .preview-9-16-1{
        order: 1;
    }
    .preview-9-16-2{
        order:3;
        min-width: 100% !important;
    }
    .preview-9-16-3{
        order:2;
        min-width: 48% !important;
    }
}

@media screen and (max-width: 640.98px) {
    .editor-preview-wrapper {
        width: 95%!important;
    }

    .save-render-sec .save-projectbtn, .save-render-sec .render-projectbtn {
        flex: 0 0 47%;
        /* max-width: 30%; */
        margin: 0!important;
        text-align: center;
    }

    .save-render-sec .add-campaign {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0!important;
    }

    .save-render-sec {
        max-width: 100%;
        /* margin-left: unset; */
    }

    .save-render-sec .add-campaign .form-control {
        width: 100%;
        margin-bottom: 10px;
    }

    .start-end-time input {
        font-size: 12px;
        /* width: 70px; */
    }

    .ar-9-16 {
        max-width: 40%;
        max-height: 100%;
    }

    .editor-preview-wrapper {
        max-width: 100%;
    }

    .play-push-left-right ul img, .expand-button img {
        max-width: 2vw;
    }

    /* .cb-col-1 {
        width: 30%;
    } */

    .play-push-left-right img,
    .expand-button img {
        width: 15px;
    }
}

@media screen and (max-width: 767px) {

    .editor-part .tab-pane {
        width: 90%!important;
    }

    .editor-part .tab-pane .tab-pane {
        width: 100%!important;
    }

    .audio-box-menu .tab-pane .row .colmn {
        max-width: 33.33%!important;
    }

    .style-box-wrapper .colmn:last-child {
        max-width: 33%!important;
    }

    .imgsizeleft{
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }

}

@media screen and (max-width: 991.98px) {
    .editor-part {
        height: 100%;
    }
    .ep-as-r-9-16 .editor-preview-wrapper .editor-preview-box {
        -webkit-transform-origin: unset!important;
                transform-origin: unset!important;
        -webkit-transform: scale(1)!important;
                transform: scale(1)!important;
    }
    
    .editor-sec .editor-container .colmn {
        max-width: 100%!important;
    }

    .colmn-preview-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* Header Start */
    header .navbar .navbar-nav .active::before {
        width: 50px;
        left: 0;
        top: 40px;
        margin: unset;
    }

    .nav-link {
        padding: 15px 0;
    }

    /* Editor Tab box */
    /* .editor-preview .editor-preview-wrapper {
        width: 90vw!important;
    }
     */
    .editor-tab-box {
        order: 3;
    }
    .search-load-box{
        margin-bottom: 0 !important;
    }
    .editor-part .tab-pane {
        width: 60%;
        margin: 0 auto;
        padding-bottom: 0 !important;
    }

    .editor-part .tab-pane .tab-pane {
        width: 100%;
        margin-bottom: 0 !important;
    }

    .style-box-wrapper .colmn.col-md-8 {
        max-width: 63%!important;
    }

    .style-box-wrapper .colmn {
        flex: unset!important;
        height: unset!important;
    }

    .editor-main {
        display: unset;
        flex-direction: unset;
        justify-content: unset;
        height: unset;
        width: unset;
    }

    .imgsizeleft{
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }

    .login-form{
        margin-top: 30px;
    }

    .video-card{
        margin-top: 30px;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }

    .project-button {
        margin-top: 30px;
    }

    .banner-items{
        padding: 60px 0px;
    }
    .banner-content{
        padding: 60px 0px;
    }
    .dashborad-sec-3{
        padding: 30px 0px;
    }

    .position-text-box {
        min-width: 123px !important;
        max-width: 123px !important;
        margin: auto;
    }
    .reelmerge-editor-part .row{
        margin-top: 5% !important;
    }
}

@media screen and (max-width: 1024.98px) {
    .controls-box .col-4 {
        padding: 0;
    }

    .play-push-left-right ul {
        width: 80%;
    }

    .start-end-time input {
        font-size: 9px;
    }
}

@media screen and (max-width: 1199.98px) {
    /* .editor-preview-wrapper {
        width: 48vw!important;
    } */

    .login-content h1{
        font-size: 50px;
    }

    .template-name h4{
        font-size: 18px;
    }

    .position-text-box {
        min-width: 123px;
        max-width: 123px;
    }

}

@media screen and (max-width:1300px){
    .position-text-box{
        min-width: 122px;
        max-width: 122px;
    }
}


@media screen and (max-width: 1440.98px) {
    /* .editor-preview-wrapper {
        width: 36vw!important;
    } */
}

@media screen and (min-width:1400px){
    .save-render-sec{
        width: 100%;
    }

    .render-projectbtn {
        margin-right: 0px;
    }

    .editor-tab-menu{
        width: 100%;
    }

}

@media screen and (max-width:1700px){
    /* Style Logo Tab */
    .style-box-wrapper .colmn {
        height: 155px!important;
    }
}


@media screen and (max-width:1500.98px){
    .editor-sec .editor-container .colmn-1 {
        max-width: 28%;
    }

    .editor-sec .editor-container .colmn-2 {
        max-width: 72%;
    }

    /* Audio Box */
    .audio-box-menu .tab-pane .row .colmn {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 10px;
    }

    .tts-box-wrapper .form-group select {
        font-size: 12px;
    }

    .tts-box-wrapper .row .col-md-4 {
        padding: 0 5px;
    }

    .tts-box-wrapper .row:last-child .col-md-6:first-child {
        margin-bottom: 10px;
    }

    .tts-box-wrapper .row:last-child .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }


    /* Text Editor */
    .editor-font-color .swatch, .editor-font-color .no-color {
        flex: 0 0 11%;
        max-width: 10.8%;
    }

    .editor-font-color .input-color {
        max-width: 40%;
    }

    .editor-font-color .editor-rgb-wrapper {
        flex: 0 0 17%;
        max-width: 17%;
    }

    .editor-font-color .editor-rgb-wrapper input {
        font-size: 12px;
        text-align: center;
    }

    /* Media Tab */
    .media-menu-box .nav-item .nav-link,
    .audio-box-sec .audio-box-menu .nav-item .nav-link,
    .audio-box-sec .audio-box-menu .nav-item .nav-link.active {
        font-size: 10px;
    }

    /* Style Editor */
    .style-box-wrapper .colmn {
        flex: 0 0 100%;
        max-width: 100%!important;
        height: 100%!important;
    }

    .style-box-wrapper .colmn.col-md-8 {
        margin-bottom: 15px;
    }

    .position-text-box {
        width: 50%;
        margin: 0 auto;
    }


}

@media screen and (max-width:1366.98px){
    .ep-as-r-9-16 .editor-preview-wrapper .editor-preview-box {
        -webkit-transform-origin: 50% 0%;
                transform-origin: 50% 0%;
        -webkit-transform: scale(1.3);
                transform: scale(1.3);
    }

    /* #pills-style, #pills-credits {
        padding-bottom: 60px;
    } */

    .editor-preview {
        padding: 1.5vh 2vw;
    }

    .ar-1-1 {
        max-width: 55%;
        max-height: 90.1%;
    }

    .search-load-box .row {
        max-height: 68vh;
        display: flex;
        justify-content: space-around;
    }

    .scroll-wrapper > .row {
        height: 64vh;
    }

    .upload-audio-box > .row {
        height: 360px;
    }

    /* Header */
    /* header .navbar ul li a {
        font-size: 12px;
    } */

    header .navbar {
        padding: 10px 40px!important;
    }

    /* Text Editor */
    .credites-upload-box label {
        font-size: 12px;
    }

    .editor-tab-menu .nav-item .nav-link .img-wrapper {
        width: 20px;
        height: 24px;
    }

    .editor-tab-menu .nav-item .nav-link h6 {
        font-size: 10px;
    }

    .editor-preview-wrapper {
        width: 65%;
    }

    .play-push-left-right ul img,
    .expand-button ul img {
        width: 15px;
    }

    .editor-sec .editor-container .colmn-1 {
        max-width: 32%;
    }

    .editor-sec .editor-container .colmn-2 {
        max-width: 68%;
    }

    .editor-font-color .swatch, .editor-font-color .no-color {
        flex: 0 0 15%;
        max-width: 12%;
    }
}

@media screen and (min-width:1500px){
    .container-fluid{
        max-width: 1400px;
    }

    .render-projectbtn{
        margin-right: 0px !important;
    }
}

@media screen and (max-width: 1680.98px) {
    .ep-as-r-9-16 .editor-preview-box {
        -webkit-transform-origin: 50% 80%;
                transform-origin: 50% 80%;
    }
}

@media screen and (max-width: 1440.98px) {
    .ep-as-r-9-16 .editor-preview-box {
        -webkit-transform-origin: 50% 70%;
                transform-origin: 50% 70%;
        -webkit-transform: scale(1.3);
                transform: scale(1.3);
    }
}


@media (max-width: 767px) {
    header .navbar{ padding: 10px 15px !important;}
    .banner-content h1{ font-size: 35px;}
    .video-card{ margin-top: 0 !important;}
    .banner-items .banner-items-list .col-md-3 img{ width: 50px;}
    .bashboard-banner .banner-items p{ margin-left: -23px !important;}

    .dashborad-sec-3:nth-child(odd) [class*='col-']{ order: 2;}
    .dashborad-sec-3:nth-child(odd) [class*='col-'] + [class*='col-']{ order: 1;}
}

