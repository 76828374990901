@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

:root {

  --poppins: 'Poppins', sans-serif;
  --Montserrat: 'Montserrat', sans-serif;
}



@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

}

button {
  cursor: pointer !important;
}


a:hover {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}


header .navbar {
  /*background-image: url('../images/Navbg.png');*/
  background-size: cover;
  background-position: center;
  height: 70px;
}


header .navbar .navbar-nav .active {
  border-bottom: 1px solid #00F5A0;
}

.navmenuid {
  display: flex;
  align-items: center;
  justify-content: center;
}


.belldropdown button {
  background: linear-gradient(to right, #01D084, #0482D8);
  padding: 7px 20px !important;
  border-radius: 30px;
  display: inline-block;
}

.loader {
  width: 80px;
  height: 80px;
  border: 3px var(--secondary) solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  text-align: center;
}

.loader-sec {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #ffffff; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader::after {
  width: 15px;
  height: 15px;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  border-radius: 100%;
  position: absolute;
  content: "";
}

.belldropdown .dropdown-item {
  white-space: normal !important;
  background-color: #efeeee94;
  margin: 5px 0px;
  font-size: 14px;
}


.navmenuid .text h6 {
  font-size: 12px;
  color: #02BD99;
}

.navmenuid .text {
  color: #fff;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  margin-left: 10px;
}

header .dropdown-menu {
  position: absolute !important;
  left: -105px !important;
  top: 44px !important;
}

header .navbar ul li a {
  color: #fff !important;
  font-family: var(--poppins) !important;
  transition: .2s ease-in-out;
  padding: 0px 0px;
}

header .navbar ul li:hover a {
  color: #00FFC2 !important;
  transition: .2s ease-in-out;
}

header .navbar ul li {
  margin-right: 30px;
}


header button#dropdownMenuButton span {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;

  cursor: pointer;
  transition: .2s ease-in-out;
  overflow: hidden;

}

.dropdown-menu.show {
  display: block;
  transition: .2s ease-in-out;
}

header .dropdown-toggle::after {
  display: none !important;
}

header .dropdown button {
  padding: 0;
  border: none;
  background-color: transparent;
}

header .dropdown button:focus {
  box-shadow: none;
  outline: none !important;
}

header .dropdown-item:hover {
  background-image: linear-gradient(to right, #00C2FF, #00E9D8, #00FFC2);
}

.bashboard-banner .banner-items-list {
  background: linear-gradient(0.25turn, #842DD1, #AB2DBA, #E22D9A);
  border-radius: 15px;
  padding: 5px;
}

.bashboard-banner .banner-items p {
  color: #fff !important;
  font-size: 14px;
}

.bashboard-banner .banner-items a {
  margin-top: 15px;
}

.bg2 {
  background: linear-gradient(0.25turn, #2C7180, #1EAB91, #14D29D) !important;
}

.bg3 {
  background: linear-gradient(0.25turn, #2370BE, #4BB1CE, #4BB4CF) !important;
}

.banner-content {
  padding: 136px 0px;
}

.banner-items {
  padding: 136px 0px;
}


.bashboard-banner {
  position: relative;
  margin-top: 70px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1000ms linear infinite;
}

.bashboard-banner::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #15191E;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.bashboard-banner {
  /*background-image: url('../images/Mask\ Group\ 3.png');*/
  background-size: cover;
  background-position: top center;
  width: 100%;

  background-repeat: no-repeat;

}

.banner-content .watch-demo-btn button {
  background-image: linear-gradient(to right, #00C2FF, #00E9D8, #00FFC2);
  padding: 15px 58px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--poppins);
  cursor: pointer;
  overflow: hidden;
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}



.banner-content .watch-demo-btn button span {
  margin-right: 5px;

}


.banner-content h4 {
  font-size: 39px;
  font-family: var(--Montserrat);
  font-weight: 300;
}

.banner-content h1 {
  font-size: 73px;
  font-weight: 800;
  font-family: var(--Montserrat);
}

.banner-content h1 span {
  color: #00F5A0;
}

.banner-content p {
  width: 544px;
  font-family: var(--poppins);
  font-size: 18px;
  font-weight: 300;
}

.dashborad-sec-3 {
  position: relative;
  overflow: hidden;
  padding: 80px 0px;
  background-color: #15191E;
}

.banner-video-project {
  background-color: #15191E;
  padding: 40px 0px;
}

.banner-video-project .video-project h5 {
  font-size: 40px;
  font-family: var(--poppins);
}

.banner-video-project .video-project h5 span {
  color: #00F8B8;
}


.banner-video-project .video-project a span {
  color: #fff;
  margin-left: 20px;
}

.video-card {
  background-image: linear-gradient(to bottom, #00FDC3, #2DA8C7);
  border-radius: 10px;
  padding-top: 1px;
}


.project-create-btn button:hover {
  color: #2fcac9 !important;
}


.ClipsReel-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .8s ease-in-out;
  background-color: #000000a3;
  border-radius: 10px;
  clip-path: circle(30.3% at 100% 0);
}

.ClipsReel-overlay ul {
  height: auto;
}

.ClipsReel-overlay ul li a span {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vidoe-play-icon span i {
  color: #fff !important;
}


.ClipsReel-overlay ul li:hover i {
  color: #00F5A0 !important;
}

.ClipsReel-overlay ul li:hover a {
  color: #00F5A0;
}

.ClipsReel-overlay ul li a {
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ClipsReel-overlay ul li a i {
  font-size: 18px;
  color: #000;
}


.video-img:hover .ClipsReel-overlay {
  opacity: 1;
  transition: .8s ease-in-out;
  clip-path: circle(141.4% at 100% 0);
}

.video-card .video-img {
  border-radius: 5px;
  position: relative;
  margin: 10px;
  text-align: center;
}

.video-card .video-title {
  padding: 10px;
  padding-top: 0px;
}

.video-card .video-title .vidoe-play-icon span {
  cursor: pointer;

}

.video-name {
  background-color: #B1FCFF;
  width: 100%;
  padding: 5px 10px;
  border-radius: 30px;
}

.video-name p {
  color: #000;
  font-family: var(--poppins);
  font-size: 14px;
  padding-left: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
}

.vidoe-play-icon span img {
  width: 45px;
}

.banner-video-project .video-project a {
  color: #fff;
  font-size: 25px;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover span {
  color: #00F5A0;
}





.dashborad-sec-3-content h4 {
  font-size: 30px;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}


.dashborad-sec-3-content h4:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.dashborad-sec-3-content h6 {
  font-size: 18px;
  font-family: var(--poppins);
  color: #7BFFE9;
  text-transform: uppercase;

}

.dashborad-sec-3-content p {
  font-size: 18px;
  font-family: var(--poppins);
  font-weight: 200;
}

.imgsizeleft {
  margin-left: 50px;
}

.imgsizeright {
  margin-left: -320px;
}


.logoimg {
  /*background-image: url('../images/BG.png');*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}



.login-box {
  padding: 0px 0px 30px;
  min-height: calc(100vh - 132px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-content h1 {
  font-size: 55px;
  font-family: var(--Montserrat);
  font-weight: 800;
}

.login-content h1 span {
  color: #00F8B8;
}

.login-content p {
  width: 90%;
  font-size: 18px;
  font-family: var(--poppins);
  font-weight: 300;
  margin: auto;
  color: #C1C1C1;
}

.login-content h5 {
  font-size: 29px;
  font-family: var(--poppins);
}



.login-form {
  padding: 50px;
  background-color: #101010;
  border-radius: 25px;
  border: 1px solid#00E5FF !important;
}

.login-form h4 span {
  color: #00F8B8;
}

.login-form h4 {
  font-family: var(--Montserrat);
  font-size: 26px;

}

.login-form button {
  background-image: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  color: #fff;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: var(--poppins);
  cursor: pointer;
}

.login-form button:focus {
  color: #00b889 !important;
  background-color: #fff !important;
}

.login-form button:focus {
  background-image: none !important;
}

.login-form .forgot a {
  color: #C3C3C3 !important;
}

.login-form .forgot span:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.login-form .form-group input {
  background-color: transparent !important;
  border-color: #34B8CC !important;
  border-radius: 10px !important;
}

.login-form .form-group input:focus {
  box-shadow: none !important;
}

.login-form .form-group .form-control {
  color: #fff !important;
}


.login-form a span {
  color: #5EACB1;
  margin-left: 5px;
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li a {
  color: #fff;
  font-size: 16px;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li a:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.footer-copyright p a:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li+li {
  margin-left: 40px;
}

.footer .footer-copyright p {
  font-size: 16px;
  font-family: var(--poppins);
}

.footer .footer-copyright p a {
  transition: .2s ease-in-out;
}

.footer {
  background-color: #0000007a;
  padding: 10px 0px;
}



.footerbg {
  background-color: #000000;
  padding: 15px 0px;
}




.banner-items .banner-items-list .col-md-3 img {
  transform: scale(1);
  transition: .4s ease-in-out;
}

/* .banner-items .banner-items-list{
    transform: scale(1);
    transition: .4s ease-in-out;
}

 .banner-items .banner-items-list:hover{
    transform: scale(1.05);
    transition: .4s ease-in-out;
}  */

.banner-items .banner-items-list:hover .col-md-3 img {
  transform: scale(0.9);
  transition: .4s ease-in-out;
}



.project-banner {
  padding: 20px 0px;
  background-color: #15191E;
}


.project-header-name .project-conut h6 {
  color: #A2A2A2;
  font-size: 25px;
  font-family: var(--poppins);
  margin-left: 30px !important;
  position: relative;
  font-weight: 200;
}

.project-header-name .project-conut h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: #A2A2A2;
  top: 3px;
  left: -15px;
}



.project-header-name .project-item {
  font-size: 25px;
  font-family: var(--poppins);
  font-weight: 600;
}



.video-img img {
  width: 100%;
  height: 192px;
}


.project-button .project-create-btn button {
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-image: linear-gradient(to right, #36D6FF, #C867FF);
  border: none;
  font-size: 14px;
  font-family: var(--poppins);
  font-weight: 600;
  cursor: pointer;
}

.project-button .project-create-btn button:focus {
  background-color: #fff !important;
  background-image: none !important;
  color: #2fcac9 !important
}

.project-input-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.project-input-box .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.project-input-box .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.project-input-box .input-group input:focus {
  box-shadow: none !important;
}

.project-input-box ::placeholder {
  color: #7A7A7A !important;
  font-size: 14px;
  font-family: var(--poppins);
}

.project-input-box {
  margin-right: 20px;
}

.project-banner .bottom-line {
  border-bottom: 1px solid #A2A2A2;
  padding-bottom: 20px;
  display: block;
  width: 98%;
  margin: auto;
}

.project-banner {
  min-height: 100vh;
  padding-bottom: 40px;
  padding-top: 90px;
}



.login-form .form-group label {
  font-size: 20px;
  font-family: var(--poppins);
  color: #C3C3C3;
  margin-left: 10px;
}

.login-form .form-group input {
  height: 50px;
}

.login-form ::placeholder {
  font-size: 16px;
  color: #707070;
  font-family: var(--poppins);
  padding-left: 10px;
}

.btn-change6 {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;

}






.login-form button:hover {
  color: #00b889 !important;
}


.btn-change6:hover {
  box-shadow: 1100px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.medium-btn {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}


.medium-btn:hover {
  box-shadow: 300px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.btn-change7 {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}

.btn-change7:hover {
  box-shadow: 400px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}

.toggleupdate {
  background: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  padding: 7px 30px;
  border-radius: 30px;
  color: #fff;
  height: 45px;
}

.toggleupdate:hover {
  color: #2fcac9;
}

.cancl-btn a {
  color: #fff;
}

.btn-cancl {
  position: relative;
  z-index: 9999;
  display: inline-block;
}

.cancl-btn {
  background: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: #000;
  overflow: hidden;
  z-index: 2;
  position: relative;
}

.cancl-btn:hover {
  color: #fff !important;
}


.cancl-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to left, #00D3EB, #16B891);
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 30px;

}

.cancl-btn:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  color: #fff !important;
}


.fixed-me {
  /*background-image: url('../images/Navbg.png');*/
  background-size: cover;
  background-position: center;

  margin-bottom: 5px;
  position: fixed;
  top: 0;
  z-index: 8888;
  width: 100%;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}






.watch-demo-btn button {
  position: relative;
  z-index: 999;
}

.watch-demo-btn button::before {
  content: '';
  width: 0%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 30px;
  color: #00F5A0;
  transition: .3s ease-in-out;
  z-index: -1;
}

.watch-demo-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  border-radius: 30px;
}

.watch-demo-btn button:hover {
  color: #00b889 !important;
  transition: .4s ease-in-out;
}

.select-box .form-group {
  width: 260px;
  border: 1px solid #00FFD4;
  border-radius: 5px;
  margin-right: 20px !important;
}

.select-box .form-group select {
  border: none !important;
}

.select-box .form-group select:focus {
  box-shadow: none !important;
}

.select-box .form-group select {
  background-color: #101010;
}

.search-select .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.search-select .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.search-select .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.search-select .input-group input:focus {
  box-shadow: none !important;
}

.search-select ::placeholder {
  color: #8b7676 !important;
  font-size: 14px;
  font-family: var(--poppins);
}



.premium-header {
  background-color: #303030;
  padding: 15px 0px;
  margin-top: 65px;
}

.select-box select {
  color: #fff !important;
}


.template-name h4 {
  font-size: 22px;
  font-family: var(--poppins);
  font-weight: 400;
}



.template-number h6 {
  color: #A2A2A2;
  font-size: 22px;
  font-family: var(--poppins);
  margin-left: 30px;
  position: relative;
  font-weight: 200;
}


.template-number h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 18px;
  background-color: #A2A2A2;
  top: 4px;
  left: -15px;
}


.template-body {
  padding: 25px 0px;
  background-color: #171717;
  min-height: 100vh;
}

.template-names h6 {
  color: #DEDFDD;
}


/* .template-body .row {
    overflow-y: auto;
    height: 60vh;
} */




.template-body ::-webkit-scrollbar {
  width: 5px;
}

.template-body ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.template-body ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}


.customModal .modal-dialog .modal-body {
  height: 60vh;
}

/*.customModal .modal-dialog .modal-footer button{*/
/*    background:linear-gradient(to left , #00D3EB , #16B891);*/
/*    padding: 7px 25px;*/
/*    border:none;*/
/*    color:#fff;*/
/*    border-radius: 5px;*/
/*}*/

.customModal .modal-dialog .modal-footer button:hover {
  color: #2fcac9;
}

.customModal .modal-dialog .modal-footer button:focus {
  background: #fff;
  color: #2fcac9;
}

.customModal .modal-dialog .modal-footer {
  border: none;
}

.customModal .modal-scrollbar {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.modal-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.modal-scrollbar::-webkit-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px;
  height: 10vh !important;
}



/* .template-body ::-webkit-scrollbar-thumb:hover {
  background: #b30000;
} */



.modal-header .close {
  padding: 0px !important;
  margin: 0px !important;
}

.modal-header {
  width: 100%;
  margin: auto;
}

.modal-header .modal-title span {
  color: #00F5A0;
}

.modal-dialog {
  max-width: 1000px !important;
}




.modal-content {
  background-color: #0C0C0C;
  color: #fff !important;
}

.modal-title button span {
  color: #fff !important;
}

.facebook-item-box {
  border: 1px solid#707070;
  border-radius: 10px;
  background-color: #1C1C1C;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box:hover {
  border: 1px solid #00F5A0;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box h6 {
  color: #6A6A6A;
}

.facebook-item-box h5 {
  font-size: 14px;
}

.vidoe-play-icon {
  margin-top: 6px;
}



/* editor css start */


.editor-sec {
  background: #000000;
  min-height: 95vh;

  overflow: hidden;
}

.save-render-sec {
  background-color: #0c0c0c9e;
  padding: 10px;
}

/* .render-projectbtn {
    margin-right: 85px;
} */
.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}

.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}


.editor-part ::-moz-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part ::-moz-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part ::-moz-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px !important;
  height: 10px !important;
}








.editor-part::-webkit-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part::-webkit-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px !important;
  height: 10px !important;
}





.save-render-sec .add-campaign input {
  border-radius: 30px;
  border: none;
  background: #161616;
  width: 290px;
  height: 42px;
}

.save-render-sec ::placeholder {
  color: #A5A5A5;
}

.save-render-sec .add-campaign .form-control {
  color: #A5A5A5;
}

.save-render-sec .save-projectbtn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.save-render-sec .render-projectbtn button {
  background: linear-gradient(to right, #A72CE3, #CB3B71);
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.editor-tab-menu .nav-item .nav-link h6 {
  color: #fff;
  margin-top: 5px;
  font-size: 12px;
  font-family: var(--poppins);
  margin-bottom: 0px;
}

.editor-tab-menu .nav-item {
  padding: 0px 10px;
}

.editor-tab-menu .nav-item .nav-link.active {
  background: #414141;
  border-radius: 10px;
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link.active h6 {
  color: #00FFB1;
}

.editor-tab-menu .nav-item .nav-link {
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link img {
  width: 17px;
}

.editor-tab-menu {
  padding: 10px;
}

.editor-preview {
  padding: 40px 0px;
  background: #333333;
  position: relative;
  /* width: 110%; */
}


.story-part-sec .story-para p {
  font-size: 12px;
  font-family: var(--poppins);
  color: #fff;
}

.story-part-sec .story-para {
  background-color: #242424;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid #707070;
}

.editor-preview-box {
  width: 656px;
  height: 369px;
  overflow: hidden;
  margin: auto;
}

.para-active {
  background-color: #2DA882 !important;
}

.slide-box ul li {
  width: 150px;
  height: 70px;
  border: 1px solid #434343;
  background-color: #101010;
  margin: 0px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #9A9A9A;
  border-radius: 10px;
  font-size: 14px;
}


.slide-sec {
  background-color: #0c0c0c;
  padding: 15px;
}

.slide-box .plus-icon i {
  color: #565656;
}

.start-end-time input {
  /* width: 100px; */
  background: transparent;
  border: none;
  color: #fff !important;
}

.start-end-time input:focus-visible {
  outline: none !important;
}

.start-end-time input.form-control {
  color: #fff;
}

.start-end-time ::placeholder {
  color: #fff;
}


.controls-box {
  background: #000;
  padding: 20px;
  width: 657px;
  margin: auto;
  position: relative;
  z-index: 999;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 3px 5px -1px #000;
}



.controls-box .start-end-time ul li>input:focus {
  box-shadow: none !important;
}

.save-projectbtn button:hover {
  color: #2fcac9;
}

.save-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #2fcac9 !important;
}

.add-campaign input:focus {
  box-shadow: none;
}

.render-projectbtn button:hover {
  color: #2fcac9;
}

.render-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #2fcac9 !important;
}

.style-upload-part {
  background: #303030;
  /* padding: 10px; */
  border-radius: 10px;
}

.style-upload-box {
  background-color: #171717;
  height: 77px;
  border: 1px dashed #707070;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style-upload-box .custom-file-input {
  position: absolute;
  top: 0;
  opacity: 0;
  background: red;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.style-upload-box h6 {
  font-size: 12px;
  margin-bottom: 0px;
  color: #8F8F8F;
}

.style-upload-box .custom-file {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.position-text-box {
  background: #303030;
  padding: 10px 10px;
  border-radius: 10px;
}

.position-text-box .text-position-dropdown label {
  font-size: 14px;
  margin-left: 14px;
}

.position-text-box .text-position-dropdown .box:hover {
  background-color: #00BF85;
  transition: all .2s;
  border: 2px solid #00BF85;
}

.position-text-box .text-position-dropdown .box.box-active {
  background-color: #00BF85 !important;
  border: 2px solid #00BF85 !important;

}

.position-text-box .text-position-dropdown .box {
  margin: 5px;
  border: 2px solid #707070;
  border-radius: 0;
  background-color: #636363;
  cursor: pointer;
  padding: 10px;
  width: 20px;
  height: 20px;
  color: #fff;
  transition: all .2s;
}

.position-text-box .text-position-dropdown .box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.remove-logo-switch-btn .form-check label {
  font-size: 14px;
  color: #8F8F8F;
}

.remove-logo-switch-btn .form-check .form-check-input {
  margin-top: 2px;
  margin-left: 10px;
  font-size: 24px;
}

.style-remove-cricle {
  width: 50px;
  height: 50px;
  background-color: #000;
  border-radius: 50%;
  border: 2px solid #686868;
}

.dimmer-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.form-duration {
  color: #fff !important;
  padding: 5px ​10px 5px;
  border-radius: 4px !important;
  border: 1px solid #707070;
  background-color: #0C0C0C !important;
  height: auto;
  box-shadow: none;
  resize: none;
  height: inherit;
  letter-spacing: 1px;
}

.dimmer-wrapper .dimmer-light h6 {
  font-size: 14px;
}

.dimmer-switch-btn .remove-logo-switch-btn .form-check-input {
  margin-top: 2px;
  font-size: 24px;
}

.swap-template-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.swap-template-wrapper .swap-template-box {
  background-color: #101010;
  height: 100px;
}

.swap-template-wrapper h6 {
  font-size: 14px;
}

.editor-tab-menu {
  /* width: 110%; */
  background: #232323;
}



/* media tab css start */




.media-menu-box .nav-item .nav-link {
  color: #fff;
  background-color: #3E3E3E;
  padding: 6px 20px;

  font-size: 13px;
  padding: 9px;
  font-family: var(--poppins);
  text-align: center;

}

.media-menu-box .nav-item .nav-link.active {
  background-color: #fff;
  color: #10CA9C;
  /*font-size: 13px;*/
  /*padding: 9px;*/
  font-family: var(--poppins);
  text-align: center;
}

.media-search-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #979797;
  cursor: pointer;
}

.media-search-box .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: none;

}

.media-search-box .input-group-prepend {
  margin-left: 0px;
}

.media-search-box input:focus {
  box-shadow: none;
}

.search-laod {
  background-color: #101010;
  height: 100px;
}

.media-upload-box button {
  background: linear-gradient(to right, #B376FF, #4AC6FF);
  border: none;
  color: #fff;
  border-radius: 30px;
}

.media-upload-box {
  position: relative;
}

.media-upload-box .custom-file {
  position: absolute;
  top: 0px;
}

.media-upload-box .custom-file .custom-file-input {
  opacity: 0;
  background-color: red;
  border-radius: 30px;
  font-size: 2px;
  position: absolute;
  top: 0px;
  cursor: pointer;
}

/* media start css */


.credites-wrapper textarea {
  background-color: #0C0C0C;
  border: 1px solid #707070;
}

.credites-wrapper textarea:focus {
  background-color: #0C0C0C;
  box-shadow: none;
}

.credites-wrapper textarea.form-control {
  color: #787878;
}


.credites-wrapper label {
  font-family: var(--poppins);
}

.credites-upload-box .outer-images {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
}

.credites-uplaod {
  position: relative;
  height: 100px;
  background-color: #171717;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #707070
}

.credites-uplaod h6 {
  font-size: 14px;
  color: #8F8F8F;
}

.credites-uplaod .custom-file {
  position: absolute;
  top: 0;
}


.credites-uplaod .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  height: 261%;
  font-size: 1px;
  cursor: pointer;
}

.credites-upload-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.remove-outer-logo .form-check-input {
  margin-left: -5px;
  font-size: 24px;
  margin-top: 0px;
}

.outro-template-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.outro-template-box h6 {
  font-family: var(--poppins);
}

.outer-images-box {
  background-color: #000000;
  height: 100px;
}


/* audio editor css start */



.audio-box-sec .audio-box-menu .nav-item .nav-link.active {
  background-color: #fff;
  color: #10CA9C;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  font-family: var(--poppins);
}

.audio-box-sec .audio-box-menu .nav-item .nav-link {
  background-color: #3E3E3E;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-family: var(--poppins);
  padding: 10px;
  text-align: center;
}

.horigental-line {
  border: 1px solid #BEBEBE;
  width: 100%;
  display: block;
}

.audio-music-box {
  height: 100%;
}

.audio-music-box .card {
  background: linear-gradient(to bottom, #00EDBC, #009DB0);
  border-radius: 10px;
  transition: .4s ease-in-out;
  height: 100%;
}

.audio-music-box .card:hover {
  transform: scale(1.05);
  transition: .4s ease-in-out;
}

.audio-music-box .card .card-footer {
  background-color: #54D1CE;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}


.audio-music-box .card .card-title {
  padding: 10px;
  font-size: 14px;
}

.audio-music-box .card-body {
  padding: 0px;
}

.card-music-name h6 {
  font-size: 14px;
}

.audio-slide-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-voice-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-slide-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-language-box .form-group .form-select {
  padding-right: 0px;
}

.audio-voice-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-voice-box .form-group .form-select {
  padding-right: 0px;
}

.audio-add-text-box textarea {
  background: #0C0C0C;
  border-color: #707070;

}

.audio-add-text-box input {
  background: #0C0C0C !important;
  border-color: #707070 !important;

}

.audio-add-text-box textarea:focus {
  background: #0C0C0C;
  box-shadow: none;
}

.audio-add-text-box textarea.form-control {
  color: #787878;
}

.tts-box-wrapper {
  background: #303030;
  border-radius: 10px;
  padding: 10px;
}

.audio-preview-btn button {
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}


.audio-preview-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}

.audio-library-boxs .audio-upload-btn button {
  border-radius: 30px;
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  height: 45px;
  color: #fff;
}

.audio-upload-btn {
  position: relative;
}






.audio-library-boxs .audio-upload-btn .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  border-radius: 30px;
  font-size: 1px;
  height: 45px;
  cursor: pointer;
}

.audio-library-boxs .audio-upload-btn .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  top: 0;
}


.upload-box-card .card {
  background: linear-gradient(to bottom, #B94EFF, #DB438F);
  border: none;
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.upload-box-card .card:hover {
  transform: scale(1.05);
  transition: .4s ease-in-out;
}

.upload-box-card .card-footer {
  background: #B240E6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.upload-box-card .card-title {
  padding: 10px;
  font-size: 14px;
}

.upload-box-card .card-body {
  padding: 0px;
}

.card-play-icon {
  cursor: pointer;
}


.remove-logo-switch-btn .form-check-input:checked {
  background: #C23636;
  box-shadow: none;
  border-color: #C23636;
}

.remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}



.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked {
  background: #00BF85;
  box-shadow: none;
  border-color: #00BF85;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}


header .editormenu {
  left: -182px !important;
}

.slide-box {
  overflow-x: auto;
  overflow-y: hidden;
}

.text-font-size {
  width: 120px;
}

.text-box-sec .text-box-wrapper select {
  background-color: #0C0C0C;
  color: #fff;
}

.text-box-sec .text-box-wrapper .text-font-style select {
  border-color: #00F5A0;
}


.font-style-boxs .btn-group button {
  background-color: #0C0C0C;
  margin: 0px 10px;
  border-radius: 5px !important;
}



.profile-sec {
  background-color: #171717;
  padding: 120px 0px 60px;
  min-height: 100vh;
  padding-top: 90px;
}

.user-img {
  position: relative;
  width: 100px;
  height: 100px;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow: hidden;
}

.user-img .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 65px);
  margin-bottom: 0;
  top: 0;
  left: 0;
  background: red;
  font-size: 1px;
  border-radius: 50%;
  opacity: 0;
}


.user-img .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  background: orange;
  border-radius: 100%;
  cursor: pointer;
}

.user-details h5 {
  font-size: 24px;
  font-family: var(--poppins);
  color: #fff;
}

.user-details button {
  padding: 10px 30px;
  border: none;
  background: linear-gradient(to right, #16B891, #00D3EB);
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--poppins);
}

.user-details .user-img span {
  color: #14BB9B;
}


.form-box .form-group input {
  background-color: transparent !important;
  border: none;
  padding: 0px;
}

.form-box .form-group input:focus {
  box-shadow: none !important;
}

.form-box .form-group {
  background-color: #1E1E1E;
  color: #fff;
  padding: 5px 20px;
  border-radius: 10px;
  border: 1px solid #707070;
}

.formactive {
  border: 1px solid #00F8B8 !important;
}

.form-box .form-group .form-control {
  color: #fff !important;
  font-weight: 200;
  font-size: 14px;
  font-family: var(--poppins);
}

.form-box .form-group ::placeholder {
  color: #6c757d !important;
}


.personal-details-box h6 {
  font-size: 20px;
  font-family: var(--poppins);
  color: #fff;
}

.form-box button {
  background: linear-gradient(to right, #36D6FF, #C867FF);
  border-radius: 10px;
  border: none;
  height: 45px;
  color: #fff;
}

.form-box button:hover {
  color: #2fcac9 !important
}

.form-box button:focus {
  background: #fff;
  color: #2fcac9;
}

.form-box {
  background-color: #000;
  padding: 50px;
  border-radius: 30px;
}


.gdpr-sec {
  padding: 80px 0px 80px;
  min-height: 100vh;
  background: #171717;
}

.gdpr-update-email {
  padding: 30px;
  background: #000000;
  border-radius: 10px;
}

.gdpr-sec .gdpr-update-email .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.gdpr-update-email .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}




.gdpr-update-email .form-group input:focus {
  box-shadow: none !important;
}

.gdpr-update-email .form-group .form-control {
  color: #fff;
  font-weight: 200;
  font-size: 14px;
}

.gdpr-update-email .form-group ::placeholder {
  color: #6c757d;
}

.gdpr-update-btn button {
  background: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  color: #fff;
  border-radius: 30px;
  padding: 7px 30px;
}

.gdpr-update-btn button:hover {
  color: #2fcac9;
}

.gdpr-update-btn button:focus {
  background: #fff;
  color: #2fcac9;
}


.manage-data-box {
  background: #000000;
  padding: 40px 20px;
  border-radius: 10px;
}

.manage-data-box p {
  font-size: var(--poppins);
}

.manage-data-box p span {
  color: #f27277;
}

.manage-update-btn button {
  border: none;
  padding: 7px 20px;
  border-radius: 30px;
  color: #fff;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.manage-update-btn button::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 30px;
  z-index: -1;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover {
  color: #2fcac9;
  position: relative;
  z-index: 999;
  overflow: hidden;
}



.add-account-sec {
  padding: 60px 0px;
  background: #171717;
  min-height: 100vh;
  padding-top: 90px;
}
.add-account-sec .infoTitle{
  color: #fff !important;
}

.add-account-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

.add-account-box .form-group input {
  background: transparent;
  border: none;
  padding: 0px;
}

.text-muted {
  color: #ffffffbd !important;
}


.add-account-box .form-group input:focus {
  box-shadow: none;
}

.add-account-box .form-group ::placeholder {
  color: #6c757d;
  font-weight: 200;
}

.add-account-box .form-group .form-control {
  color: #fff;
  font-weight: 200;
}

.add-account-box .form-group select {
  background: transparent;
  border: none !important;
  padding: 0px;
  text-align: left;
  height: 24px !important;
  color: #6c757d;
}

.add-account-box .form-group select:focus {
  box-shadow: none;
}

.add-account-box .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}

.add-account-box .form-group:focus-within {
  border-color: #01D084 !important;
}

.add-account-save-btn button {
  background: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  padding: 7px 30px;
  color: #fff;
  font-family: var(--poppins);
  border-radius: 30px;
}


.add-account-save-btn button:hover {
  color: #2fcac9;
}

.add-account-save-btn button:focus {
  background: #fff;
  color: #2fcac9;
}

.team-member-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

.text-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  /*white-space: nowrap;*/
  /* width: 150px; */

}




.help-support-banner {
  background-color: #000000;
  margin-top: 65px;
  overflow: hidden;
}


.customer-sec {
  padding: 60px 0px;
  background-color: #242424;
}

.customer-content h6 {
  color: #00FFBA;
}

.customer-content h5 {
  font-size: 28px;
  font-family: var(--poppins);
}

.customer-content a {
  padding: 10px 30px;
  background-color: #01D084;
  color: #fff;
  border-radius: 30px;
  margin-top: 10px;
  display: inline-block;
  font-weight: 600;
}

.customer-content a:hover {
  color: #01D084;
  font-weight: 600;
}

.gdpr-update-email .form-group:focus-within {
  border-color: #01D084 !important;
}

.form-group:focus-within {
  border-color: #01D084 !important;
}

.secarchicone-part #searchimg {
  position: relative;
  left: 40%;
}



.help-banner-content .input-group .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-left: 0px;
  background-color: #424a4bcf;
  border: none;
  color: #7A7A7A;
  font-size: 13px;
  padding-right: 0px;
  cursor: pointer;
}

.help-banner-content .input-group .input-group-prepend {
  margin-right: 1px;
}

.help-banner-content .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin: 0px;
  background-color: #424a4bcf;
  border: none;
}

.help-banner-content .input-group input:focus {
  box-shadow: none;
}

.help-banner-content .input-group ::placeholder {
  color: #7A7A7A;
}

.help-banner-content .input-group .form-control {
  color: #fff;
}


.help-banner-content h3 {
  font-size: 42px;
  font-family: var(--poppins);
  font-weight: 600;
}

.help-banner-content .input-group.mt-4 {
  height: 50px;
}

.help-banner-content h3 span {
  color: #00F5A0;
}

.important-topics span {
  width: 100px;
  height: 100px;
  background-color: #00B5DF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 2px solid #fff;
}


.important-topics span img {
  max-width: 50%;
}

.important-sec {
  background-color: #242424;
  padding: 60px 0px;
}


.important-topics {
  background: linear-gradient(to bottom, #00E2A4, #00B5DF);
  padding: 40px;
  border-radius: 15px;
  transition: .4s ease-in-out;
  height: 247px;
}

.important-topics:hover {
  transform: scale(1.02);
  transition: .4s ease-in-out;
}

.popular-see {
  background-color: #000000;
  padding: 60px 0px;
}

.popular-video-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: var(--poppins);
}

.important-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: var(--poppins);
}

.player-box {
  background: linear-gradient(to bottom, #00FDC3, #2DA8C7);
  padding: 10px;
  border-radius: 10px;
}

.support-sec {
  padding: 60px 0px;
  background-color: #242424;
}

.support-content h6 {
  font-size: 25px;
  font-family: var(--poppins);
  color: #00F5A0;
}

.support-content h3 {
  font-size: 40px;
  font-family: var(--poppins);
}

.support-content p {
  font-size: 18px;
  width: 90%;
  font-family: var(--poppins);
}

.support-content button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  color: #fff;
  padding: 7px 30px;
  border-radius: 30px;
  border: none;
}

.upgrade-menu .nav .nav-link:hover {
  background-color: #00BBAE;
}

.support-content button:hover {
  color: #2fcac9;
}

.support-content button:focus {
  background: #fff;
  color: #2fcac9;
}

.traning-sec {
  background-color: #232323;

  min-height: 100vh;
}

.popular-topics-content {
  background-color: #171717;
  padding: 20px 30px;
  min-height: 100vh;
}

.popular-topics-content .vidoebtn {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  color: #fff;
}

.popular-topics-box .nav {
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px 0px 20px 0px;
}

.popular-topics-box .nav .nav-link {
  color: #fff;
  padding-left: 40px;
}


.popular-topics-box .nav .nav-link.active {
  background-color: #00BBAE;
}



.integration-box .img-wrraper {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-box li {
  max-width: 120px;
  width: 100%;
  background-color: #000000;
  text-align: center;
  padding: 20px;
  margin: 0 0;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 40%), 0 0 20px 0 rgb(0 0 0 / 40%);
  display: block;
  color: #fff;
  cursor: pointer;
  transform: scale(1.05);
  transition: .4s all;
}

.integration-box li:hover {
  transform: scale(1);
  transition: .4s all;
}


.integration-box ul li a {
  color: #fff;
}


.integration-sec {
  background: #171717;
  padding: 120px 0px 60px;
}


.my-connection {
  background: #171717;
  padding: 60px 0px;
}

.connection-box {
  background: #000000;
  padding: 20px;
  border-radius: 10px;
  scroll-margin: 80px 0 0 0;
}

.facebook-delete button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.facebook-connection {
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.facebook-connect-account button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.facebook-connect-account button:hover {
  color: #2fcac9;
}

.facebook-connect-account button:focus {
  background: #fff;
  color: #2fcac9;
}

.facebook-delete button:hover {
  color: #2fcac9;
}

.facebook-delete button:focus {
  background: #fff;
  color: #2fcac9;
}

.youtube-connect button:hover {
  color: #2fcac9 !important;
}

.youtube-connect button:focus {
  background: #fff;
  color: #2fcac9;
}

.youbtube-connection .youtube-connect button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.webinar-sec {
  padding: 60px 0px;
  /*background: url('../images/webnibeimg.png') 50%/cover no-repeat;*/


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #00FBC5;
}


.webiner-content p {
  font-size: 18px;
  font-family: var(--poppins);
  color: #fff;
}

.webiner-content button {
  background: linear-gradient(to right, #00C2FF, #00FFC2);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #000;
  font-weight: 600;
}

.webiner-content button:hover {
  color: #00b889 !important;
}

.warraper {
  min-height: 100vh;
  background: #171717;
}


.upgrads-sec {
  padding: 120px 0px 60px;
  background: #171717;
}


.upgrade-menu {
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
}

.upgrade-menu .nav .nav-link {
  color: #fff;
  padding: 10px 40px;

}

.upgrade-menu .nav .nav-link.active {
  background-color: #00BBAE;

}

.upgrade-content {
  background-color: #000000;
  padding: 20px 30px;
  min-height: 100vh;
}

.upgrade-box-content {
  background-color: #171717;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}

.upgrade-heading h6 {
  background: #00BBAE;
  padding: 10px;
}

.upgrade-para {
  padding: 10px;
}

.upgrade-box-content button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 7px 30px;
  border-radius: 30px;
  border: none;
  color: #fff;
}


.upgrade-para button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.upgrade-para button:hover {
  color: #2fcac9;

}

.upgrade-para button:focus {
  background: #fff !important;
  color: #2fcac9 !important;
}

.upgrade-box-content button:hover {
  color: #2fcac9;
}

.upgrade-box-content button:focus {
  background: #fff !important;
  color: #2fcac9;
}

.tutorial-content .upgrade button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 10px 30px;
  border: none;
  color: #fff;
  border-radius: 30px;

}

.tutorial-content .upgrade button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 257px;
}

.tutorial-content .upgrade button:hover {
  color: #2fcac9;
}

.tutorial-content .upgrade button:focus {
  background: #fff;
  color: #2fcac9;
}


.modal .ecommercebox {
  max-width: 600px !important;
}


.ecommercebox .modal-body .form-group {
  width: 80%;
  margin: auto;
}

.ecommercebox .modal-body .modal-scrollbar {
  display: flex;
  align-items: center;
  justify-content: center;
}


.ecommercebox .modal-body .modal-scrollbar .form-group input {
  background: #1E1E1E;
  height: 50px;
}

.ecommercebox .modal-body .modal-scrollbar .form-group input:focus {
  border-color: #01D084;
  box-shadow: none;
}

.ecommercebox .modal-body .modal-scrollbar .form-group .form-control {
  color: #fff;
}

.ecommercebox .modal-body .modal-scrollbar {
  height: 40vh;
}



.ecommercebox .modal-body .modal-scrollbar .form-group button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: #fff;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:hover {
  color: #2fcac9;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:focus {
  background: #fff;
  color: #2fcac9;
}

.hide-table-padding .form-group {
  background-color: #1E1E1E;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}

.hide-table-padding .form-group:focus-within {
  border-color: #01D084;
}

.hide-table-padding .form-group label {
  font-size: 14px;
  color: #ffffffbd;
}

.brandonfocus:focus-within {
  border-color: #01D084;
  box-shadow: none;
}

.hide-table-padding .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.hide-table-padding .form-group input:focus {
  box-shadow: none;
}

.hide-table-padding .form-group .form-control {
  color: #fff !important;
  font-weight: 200;
}

.hide-table-padding ::placeholder {
  color: #6c757d;
}


.template-cover {
  background-image: linear-gradient(to bottom, #00FDC3, #2DA8C7);
  border-radius: 10px;
  padding-top: 1px;
  height: auto;
}

.template-cover .template-img {
  border-radius: 10px;
  position: relative;
  margin: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}

.template-time {
  position: absolute;
  background-color: #fff;
  padding: 1px 10px;
  color: #000;
  top: 5px;
  left: 5px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
}

.template-names {
  padding: 0px 0px 5px;
}

.template-img:hover .ClipsReel-overlay {
  opacity: 1 !important;
  transition: .8s ease-in-out !important;
  clip-path: circle(70.7% at 50% 50%) !important;
}


/** Custom */
.my-account-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #16d489;
}

.my-select {
  border-radius: 5px;
  border: none;
  outline: none
}

.hide-social {
  display: none !important;
}


.sweet-alert {
  color: #0C0C0C !important;
}

.modal {
  z-index: 99999;
}

.show-modal {
  display: block !important;
  background: rgba(0, 0, 0, 0.5);
}

.load-icon-help {
  color: #02c98c;
  font-size: 30px;
}

.webinar-sec {
  padding: 60px 0px;
  background: url('../src/images/webnibeimg.png') 50%/cover no-repeat;


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #00FBC5;
}


.webiner-content p {
  font-size: 18px;
  font-family: var(--poppins);
  color: #fff;
}

.webiner-content button {
  background: linear-gradient(to right, #00C2FF, #00FFC2);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #000;
  font-weight: 600;
}

.webiner-content button:hover {
  color: #00b889 !important;
}

.accnt-mngmt-btn:hover {
  color: #00b889 !important;
}

.upload-btn-wrapper .custom-file-input {
  position: absolute;
  top: 0;
}


.script-sec {
  padding: 120px 0px 60px;
  background-color: #262626;
  min-height: 100vh;
}

.script-sec .form-group label {
  color: #fff;
}

.script-sec .form-group input {
  background-color: #0D0D0D;
  height: 49px;
}

.script-sec .form-group input:focus {
  border-color: #00FFB1;
  box-shadow: none;
}

.script-sec .form-group textarea {
  background-color: #0D0D0D;
  border-color: #707070;

}

.script-sec .form-group textarea:focus {
  border-color: #00FFB1;
  box-shadow: none;
}

.script-sec .form-group .form-control {
  color: #fff;
}

.script-create-btn button,
.custom-next button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a:hover {
  color: #2fcac9;
}


.script-create-btn button:hover {
  color: #2fcac9;
}


.secarchicone-part {
  position: relative;
  animation: mymove 3s;
  animation-iteration-count: 5;
}

@keyframes mymove {
  from {
    transform: scale(0.09);
  }

  to {
    transform: scale(1.1);

  }
}


.fb-card .youtubeicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -30%);
  font-size: 30px;

}


.script-create-btn button:focus-within {
  color: #2fcac9 !important;
  background: #fff !important;
}

.active-dim {
  border: 1px solid #00f5a0;
}

.active-slide {
  border-color: #00f5a0 !important;
}

.modal-backdrop {
  position: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hide-box {
  display: none !important;
}

.story-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.preview-text {
  position: absolute;
  bottom: 0;
  padding: 20px;
}

/*color picker*/
.swatch {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

.swatch-color {
  width: 100%;
  height: 14px;
  border-radius: 2px;
}

.swatch-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.sketch-picker {
  position: absolute;
  z-index: 999;
}

.templateModel {
  margin: auto;
  max-width: 450px;
  height: auto;
}


.templateModel .modal-body {
  height: auto !important;
  box-shadow: 0 0 5px rgba(255, 255, 255, .1);
}

.btn-custom {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.btn-span {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
}

.btn-custom-disable {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.btn-custom:hover {
  color: #2fcac9;
  background: #fff;
}

.preview-text span div {
  display: inline;
}

.highlight-text {
  background-color: yellow;
}


.search-load-box ::-webkit-scrollbar {
  width: 5px;
}

.search-load-box ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.search-load-box ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}

.scroll-bar ::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.scroll-bar ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}

/* Infinite scroll */
.infinite-scroll-component {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.slide-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #10ca9c;
}

.slide-active i {
  color: #10CA9C;
  transform: scale(1.5);
}

.loader-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.delete-media {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  filter: drop-shadow(2px 4px 6px black);
  width: unset;
  height: unset !important;
}

.delete-media svg {
  position: absolute;
  right: 5px;
  top: 5px;
  fill: #00f5a0;
}

.delete-media i {
  color: #ffffff;
}

.disable-click {
  pointer-events: none
}

.custom-anchor {
  text-decoration: underline !important;
  cursor: pointer;
}

.customModal .modal-content {
  height: 90vh;
}

.reactEasyCrop_Image {
  /*width: 500px;*/
  /*height: 350px;*/
}

.crop-icon {
  position: absolute;
  top: 3px;
  left: 6px;
  width: auto !important;
  height: auto !important;
  z-index: 1;
}

.template-border {
  border: 2px;
  border-color: transparent;
}

.active-template {
  border: 2px solid #ffffff;
}

.rvt-main-container {
  width: 60%;
  margin: auto;
}

.reactEasyCrop_Container {
  width: 60%;
  margin: auto;
}

button:disabled {
  /*background: linear-gradient( to right , #688a81 , #3B91CB);*/
  cursor: auto !important;
  opacity: .3;
}

button:disabled :hover {
  background: linear-gradient(to right, #688a81, #3B91CB) !important;
  cursor: auto !important;
  opacity: .6;
}

.active-audio {
  border: 2px solid #00f5a0;
}

.preview-1-1 {
  width: 40%;
}

.preview-9-16-1 {
  /* width: 50% !important; */
  order: 1;
}

.preview-9-16-2 {
  /* width: 100% !important; */
  order: 2;
  /* margin-top: 10px; */
}

.preview-9-16-3 {
  /* width: 50% !important; */
  order: 3;
}

.icon-response {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.icon-response-span {
  width: 80px;
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  text-align: center;
}

.form-body .gdpr-update-email {
  background: transparent !important;
}

.form-body input,
.form-body textarea,
.form-body select {
  background: #1E1E1E;
  border-color: transparent;
  padding: 0px !important;
}

.form-body input:focus,
.form-body textarea:focus,
.form-body select:focus {
  background: #1E1E1E;
  border-color: transparent;
  padding: 0px !important;
}

/*loader css*/
.loader::after {
  width: 10px;
  height: 10px;
  background-color: #10CA9C;
  border-radius: 100%;
  position: absolute;
  content: "";
}

.loader {
  width: 80px;
  height: 80px;
  border: 2px #10CA9C solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  text-align: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-sec {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}


.icon-response-span:hover .wrap-icon-image {
  width: 80px;
  height: 80px;
  /* background: red; */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 0px 5px;
  transition: .4s ease-in-out;
}


.icon-response-span .wrap-icon-image {
  width: 80px;
  height: 80px;
  /* background: red; */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: .4s ease-in-out;
}

.active-push {
  border-radius: 50%;
  box-shadow: 0 0 5px #00FDC3;
  transition: .4s ease-in-out;
}

.dimmer-bg {
  background: rgba(0, 0, 0, 0.5);
}

.dimmer-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 1;
}

.aspect-ratio-wrapper:hover .dimmer-layer {
  z-index: 0;
}

.loader-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.logo-style {
  z-index: 2;
  max-width: 100px;
}

.logo-top-left {
  position: absolute !important;
  top: 0;
  left: 0;
  padding: 10px;
}

.logo-top-right {
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 10px;
}

.logo-bottom-right {
  position: absolute !important;
  bottom: 0;
  right: 0;
  padding: 10px;
}

.logo-bottom-left {
  position: absolute !important;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.logo-top-center {
  position: absolute !important;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
}

.logo-center-left {
  position: absolute !important;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 10px;
}

.logo-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.logo-center-right {
  position: absolute !important;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 10px;
}

.logo-bottom-center {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
}

input:read-only {
  cursor: auto;
}

.render-overlay {
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background: rgba(0, 0, 0, .8);
}

/* progress */
.randring-card {
  background-color: #fff;
  padding: 40px 20px;
  height: 192px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.progress-content span {
  color: gray;
  font-size: 12px;
}

.progress-content h5 {
  color: blue;
  font-size: 12px;
}

.progress-status span {
  color: blue;
  font-size: 16px;
  font-weight: 600;
}

.progress-status h5 {
  color: gray;
  font-size: 12px;
}


.bashboard-banner #bg-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}

#bg-video iframe {
  position: absolute;
  min-height: 100vh;
  min-width: 177.77vh;
  width: 100vw;
  height: 56.25vw;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.text-wrappers span {
  display: inline-block !important;
}

.bg-trans {
  background: transparent !important;
}

.text-white a {
  text-decoration: none;
  color: #ffffff;
}

.watermark-op {
  opacity: .5;
}

.center-logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo {
  top: 0;
  object-fit: contain !important;
  /*width: auto !important;*/
}

.traning-sec h6 a[href]:hover {
  color: #00BBAE;
}


/*************    Reel       **/
.reel-select select {
  color: #A5A5A5 !important;
  background: #000000;
  border: none;
}

.reel-select select:focus {
  background: #000000;
  color: #A5A5A5 !important;
  box-shadow: none;
}

.wrap-merge {
  margin-top: 65px;
}

.wrap-merge ul li {
  text-align: center;
  flex-basis: 48%;
  margin: 2px;
  background-color: #000;
  height: auto;
  display: inline-block
}

.merge-wrap-img img {
  height: 100%;
}

.crop-icon-center {
  position: absolute;
  top: 50%;
  left: 50%;
}

.crop-icon-center i {
  font-size: 30px;
}

.custom-form {
  height: auto;
  padding: 8px;
}

.custom-form:focus {
  outline: none;
}

.d-flex select:focus {
  outline: none;
}

#v-pills-client p span {
  color: #ffffff !important;
}

.my-img-wrapper {
  width: 100%;
  height: 100%;
}

.my-img-wrapper img {
  width: 100%;
  height: 100%;
}

/* body{ padding-top: 60px;} */
.help-support-banner {
  margin-top: 50px;
}


.gdpr-sec {
  padding-top: 60px;
}

.footer .footer-menu ul li a:hover {
  color: #00F5A0;
}

.footer-copyright a {
  color: #00F5A0;
}

.footer-copyright a:hover {
  color: #00c6f5 !important;
}

.integration-box li:hover {
  background: linear-gradient(180deg, #00D3EB, #16B891);
  box-shadow: 0 0 20px 0 #00D3EB, 0 0 20px 0 #16B891;
  transform: scale(1.1) !important;
}

.user-img .custom-file {
  z-index: 999;
}

.imgEdit {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.user-img:hover .imgEdit {
  opacity: 1;
  visibility: visible;
}

.imgsizeright {
  margin-left: -230px;
}


.commonModal .modal-content {
  background: none !important;
  border: none !important;
}

.commonModal .modal-body {
  background: #000;
  overflow: hidden;
  border-radius: 20px;
  padding: 25px;
}

.commonModal .embed-responsive {
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  position: relative;
  aspect-ratio: 16 / 9;
}

.commonModal .embed-responsive iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.commonModal .modal-dialog {
  max-width: 1130px !important;
}

.commonModal .close {
  position: absolute;
  right: 30px;
  top: -30px;
  opacity: 1 !important;
}

.modal {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.modal.show-modal {
  opacity: 1;
  visibility: visible;
}

.commonModal .modal-dialog {
  transform: scale(0);
  transition: all 0.5s ease;
}

.commonModal.show-modal .modal-dialog {
  transform: scale(1);
}

/* .logoimg{ margin-top: -60px;} */
.upgrads-sec {
  padding-top: 75px;
  min-height: calc(100vh - 78px);
}

.upgrade-content {
  min-height: 10px;
}

.project-banner {
  padding-top: 92px;
}


.auth-footer {
  padding: 14px 0;
}
#scrollableVideo .infinite-scroll-component,
#scrollableMeme .infinite-scroll-component{
  justify-content: center;
  gap: 10px;
}

.note-video-clip{
  width: 100% !important;
}

#docs-internal-guid-3c5ff884-7fff-eaf7-28df-8b269d3273f3{
  display: none !important;
}

/* .reelmerge-editor-part{
  margin-top: 127px;
  padding-bottom: 0 !important;
  height: 708px !important;
} */

.project-create-btn{
  min-width: 180px;
}

@media screen and (max-height: 800px) {
  .commonModal .modal-dialog {
    max-width: 940px !important;
  }
}


@media (max-width: 1699px) {
  .imgsizeleft {
    margin-left: 0;
  }

  .imgsizeleft img {
    max-width: 100%;
  }

  .imgsizeright {
    margin-left: 0;
  }

  .imgsizeright img {
    max-width: 100%;
  }

  .banner-content h1 {
    font-size: 60px;
  }

  .banner-content h4 {
    font-size: 30px;
  }
}

@media (max-width: 1199px) {
  .banner-content h1 {
    font-size: 45px;
  }

  .banner-content h4 {
    font-size: 25px;
  }

  .banner-content p {
    font-size: 15px;
  }

  .banner-video-project .video-project h5 {
    font-size: 35px;
  }

  .dashborad-sec-3-content h4 {
    font-size: 25px;
  }

  .dashborad-sec-3-content h6 {
    font-size: 16px;
  }

  .dashborad-sec-3-content p {
    font-size: 15px;
  }

  .webiner-content p {
    font-size: 15px;
  }

  .webiner-content h3 {
    font-size: 25px;
  }

  .footer .footer-copyright p {
    font-size: 13px;
  }

  .footer .footer-menu ul li a {
    font-size: 13px;
  }

  .integration-sec {
    padding-top: 30px;
    margin-top: 70px;
  }

  .help-banner-content h3 {
    font-size: 25px;
  }

  .secarchicone-part #searchimg {
    left: 0;
  }

  .popular-topics-box .nav .nav-link {
    padding-left: 20px;
  }
}

@media (max-width: 991px) {
  .footer-copyright {
    padding: 8px 0;
  }

  .navbar-collapse {
    position: fixed;
    left: 0;
    top: 71px;
    width: 100%;
    height: 100%;
    background: #000;
    padding: 30px;
    padding-top: 0;
    z-index: 1000;
  }

  .collapse.show {
    z-index: 1000 !important;
  }

  header .navbar ul li {
    margin-right: 0;
  }

  header .navbar .navbar-nav .nav-link {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  header .dropdown button {
    padding: 15px 0;
  }

  header .dropdown-menu {
    position: static !important;
    width: 100%;
  }

  .traning-sec .col-md-4,
  .traning-sec .col-md-8 {
    width: 100%;
  }

  .popular-topics-content {
    min-height: 10px;
    margin-top: 30px;
  }
  /* .reelmerge-editor-part{
    margin-top: 30px;
    height: 400px !important;
  }
  .reelmerge-editor-container{
    padding-bottom: 100px;
  } */
   .save-render-sec{
    margin:0 auto;
   }
   .navbar{
    position: fixed;
    width: 100%;
    z-index: 9;
    top: 0;
    left: 0;
   }
   .editor-sec{
    margin-top: 70px !important;
   }
}

@media (max-width: 767px) {
  .my-table-dark {
    min-width: 570px;
    overflow-x: auto;
    margin-right: 10px;
  }

  .customModal .modal-content {
    height: 100vh;
  }

  .customModal .modal-scrollbar {
    height: 80vh;
  }

  .modal-dialog {
    margin: 0;
  }

  .template-body {
    padding: 15px 0;
  }

  .banner-content h1 {
    font-size: 35px;
  }

  .banner-content h4 {
    font-size: 20px;
  }

  .banner-items {
    padding: 0 0 30px 0 !important;
  }

  .banner-content {
    padding: 20px 0 !important;
    text-align: center !important;
  }

  .help-support-banner {
    padding-top: 60px;
  }

  header .navbar {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .project-button {
    width: 100% !important;
  }

  .project-input-box {
    width: 100% !important;
  }

  .add-campaign.mr-4 {
    margin-right: 0 !important;
    width: 100% !important;
  }

  .add-campaign select {
    width: 100% !important;
    margin-bottom: 15px;
  }

  .add-account-box,
  .team-member-box,
  .gdpr-update-email {
    padding: 15px !important;
  }

  .upgrads-sec {
    padding-top: 0 !important;
    margin-top: 70px;
  }

  .login-content h1 {
    font-size: 40px !important;
  }

  .upgrade-content {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .tutorial-content {
    text-align: center !important;
    padding-bottom: 30px !important;
  }

  .tutorial-content .col-md-10.d-flex.align-items-center {
    justify-content: center !important;
    padding-top: 15px !important;
  }
  .banner-video-project{
    padding-bottom: 0px;
  }
  .dashborad-sec-3{
    padding-top: 0px;
  }
}

.active-help {
  color: #00BBAE !important;
}

.logo-img {
  max-width: 160px;
  min-width: 140px;
  overflow: hidden;
}

@media (max-width:567px) {
  .customModal .modal-scrollbar {
    height: 76vh !important;
  }
  .note-video-clip{
    height: auto !important;
  }
}

@media (max-width: 380px) {
  .facebook-connection {
    flex-direction: column;
    gap: 10px;
  }

  .facebook-delete button {
    margin-bottom: 10px;
  }
}